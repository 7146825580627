import React from 'react'
import { CMsection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/CMsection";
import { WeOfferSection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/WeOfferSection";
import { LetDiscussSection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/LetDiscussSection";
import { WhatWeDoSection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/WhatWeDoSection";
import { EngagingSteps } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/EngagingSteps";
import { DominExpertiseSec } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/DominExpertiseSec";
import { TechnologyStackSection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/TechnologyStackSection";
import { RelatedBlogSection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/RelatedBlogSection";
import { CTASection } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/CTASection";
import { ServicePageBanner } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/ServicePageBanner";
import { InnerPgSec } from "../../DigitalMarketingInnerPages/SearchEngineOptimize/InnerPgSec";
import Faqs from '../../DigitalMarketingInnerPages/SearchEngineOptimize/Faqs';
import { ContactForm } from '../../DigitalMarketingInnerPages/SearchEngineOptimize/ContactForm';

export const SEO = () => {
  return (
    <>
    <ServicePageBanner />
      {/* <InnerPgSec /> */}
      <CMsection />
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
