import React from 'react'
import { CMsection } from "../../CmsDevelopment/WordPressManagement/CMsection";
import { WeOfferSection } from "../../CmsDevelopment/WordPressManagement/WeOfferSection";
import { LetDiscussSection } from "../../CmsDevelopment/WordPressManagement/LetDiscussSection";
import { WhatWeDoSection } from "../../CmsDevelopment/WordPressManagement/WhatWeDoSection";
import { EngagingSteps } from "../../CmsDevelopment/WordPressManagement/EngagingSteps";
import { DominExpertiseSec } from "../../CmsDevelopment/WordPressManagement/DominExpertiseSec";
import { TechnologyStackSection } from "../../CmsDevelopment/WordPressManagement/TechnologyStackSection";
import { RelatedBlogSection } from "../../CmsDevelopment/WordPressManagement/RelatedBlogSection";
import { CTASection } from "../../CmsDevelopment/WordPressManagement/CTASection";
import { ServicePageBanner } from "../../CmsDevelopment/WordPressManagement/ServicePageBanner";
import { InnerPgSec } from "../../CmsDevelopment/WordPressManagement/InnerPgSec";
import Faqs from '../../CmsDevelopment/WordPressManagement/Faqs';
import { ContactForm } from '../../CmsDevelopment/WordPressManagement/ContactForm';

export const WordPressManagement = () => {
  return (
    <>
     <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
