import React from 'react'
import { CMsection } from "../../MobileAppInnerPages/IOSApps/CMsection";
import { WeOfferSection } from "../../MobileAppInnerPages/IOSApps/WeOfferSection";
import { LetDiscussSection } from "../../MobileAppInnerPages/IOSApps/LetDiscussSection";
import { WhatWeDoSection } from "../../MobileAppInnerPages/IOSApps/WhatWeDoSection";
import { EngagingSteps } from "../../MobileAppInnerPages/IOSApps/EngagingSteps";
import { DominExpertiseSec } from "../../MobileAppInnerPages/IOSApps/DominExpertiseSec";
import { TechnologyStackSection } from "../../MobileAppInnerPages/IOSApps/TechnologyStackSection";
import { RelatedBlogSection } from "../../MobileAppInnerPages/IOSApps/RelatedBlogSection";
import { CTASection } from "../../MobileAppInnerPages/IOSApps/CTASection";
import { ServicePageBanner } from "../../MobileAppInnerPages/IOSApps/ServicePageBanner";
import { InnerPgSec } from "../../MobileAppInnerPages/IOSApps/InnerPgSec";
import Faqs from '../../MobileAppInnerPages/IOSApps/Faqs';
import { ContactForm } from '../../MobileAppInnerPages/IOSApps/ContactForm';

export const IOSApps = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
