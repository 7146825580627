import React from 'react'
import '../assets/css/ServicePage.css';
import { FaArrowAltCircleRight } from 'react-icons/fa';


export const ContactForm = () => {
  return (
    <>
            <div className="container mini-container jump-forms" id="contactForm">
        <div className="cmr-contact left-brush-top">
            <div className="row mb-5 align-items-center">
                <div className="col-md-6 col-sm-6 col-12">
                    <div className="sec-title left-brush">
                        <p><span>Contact Us</span></p>
                        <p className="contact-heading">Let's Talk<br/> Business!</p>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <p className="contact-form-detail mb-0">Have questions about your idea? Drop in your details to discuss
                        with our solution consultants. We look forward to hear from you.</p>
                </div>
            </div>
            
            <div id="contactForm">
                <form method="POST" autocomplete="off" enctype="multipart/form-data" action="/leads/" name="contact_us" className="contact_us" id="contact_us" novalidate="novalidate"> 
                    <input type="hidden" name="routeName" id="routeName" value="/progressive-web-app-development.html"/> 
                    <input type="hidden" name="hidenip" id="hidenip" value="182.184.53.180"/>
                    <div className="row cmr-contact left-brush-top m-0">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-inline d-block"> 
                                <span className="has-float-label">
                                    <input type="text" className="form-control auto_remove_space" name="name" id="fullnameksljf" placeholder="e.g. Ross" value=""/>
                                    <label for="fullnameksljf">
                                        Full Name 
                                        <span className="mandetorysign">
                                            *
                                        </span>
                                    </label>
                                </span> 
                                <span className="error-message">

                                </span> 
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-inline d-block"> 
                                <span className="has-float-label">
                                    <input type="email" className="form-control auto_remove_space" id="emaillkjkl" name="email" placeholder="e.g. ross@yopmail.com" value=""/>
                                    <label for="emaillkjkl">
                                        Email 
                                        <span className="mandetorysign">
                                            *
                                        </span>
                                    </label>
                                </span> 
                                <span className="error-message">

                                </span> 
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 formInputfield">
                            <div className="form-inline d-block formInputfield"> 
                                <span className="has-float-label formInputfield">
                                    <input type="text" tabindex="-1" className="form-control auto_remove_space" autocomplete="off" name="fullname" id="fullname" placeholder="e.g. Ross" value=""/>
                                    <label for="fullname">
                                        Full Name
                                        <span className="mandetorysign">
                                            *
                                        </span>
                                    </label>
                                </span> 
                                <span className="error-message formInputfield">

                                </span> 
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 formInputfield">
                            <div className="form-inline d-block formInputfield"> 
                                <span className="has-float-label formInputfield">
                                    <input type="email" tabindex="-2" className="form-control auto_remove_space" autocomplete="off" id="email" name="email" placeholder="e.g. ross@yopmail.com" value=""/>
                                    <label for="email">
                                        Email 
                                        <span className="mandetorysign">
                                            *
                                        </span>
                                    </label>
                                </span> 
                                <span className="error-message formInputfield">

                                </span> 
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-inline d-block"> 
                                <span className="has-float-label">
                                    <input type="tel" name="phone" id="contact" maxlength="15" className="form-control auto_remove_space" placeholder="e.g. 6315411452" value=""/>
                                    <label for="contact">
                                        Phone Number
                                    </label>
                                </span>
                            </div> 
                            <span className="error-message tel-error-message"></span>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-inline d-block nov-center">
                                <span className="has-float-*label">
                                    <textarea className="form-control auto_remove_space" name="brief" maxlength="1000" id="aboutProject" rows="1" placeholder="Describe your project">

                                    </textarea>
                                </span> 
                                <span className="error-message"></span> 
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-inline nowrap form-service-row">
                                <label className="cmr-label">
                                    Services
                                </label>
                            </div>
                            <div className="checkform greatForm" itemscope=""> 
                                <input type="checkbox" name="services[]" id="hiredeveloper" value="Hire Developer(s)"/>
                                <label for="hiredeveloper" itemprop="name">
                                    Hire Developer(s)
                                </label> 
                                <input type="checkbox" name="services[]" id="webdevelopment" value="Web Development"/>
                                <label for="webdevelopment" itemprop="name">
                                    Web Development
                                </label> 
                                <input type="checkbox" name="services[]" id="mobileappdevelopment" value="Mobile App Development"/>
                                <label for="mobileappdevelopment" itemprop="name">
                                    Mobile App Development
                                </label> 
                                <input type="checkbox" name="services[]" id="uiuxservices" value="Ui/Ux Design"/>
                                <label for="uiuxservices" itemprop="name">
                                    UI/UX Design
                                </label> 
                                <input type="checkbox" name="services[]" id="maintenancesupport" value="Maintenance &amp; Support"/>
                                <label for="maintenancesupport" itemprop="name">
                                    Maintenance &amp; Support
                                </label> 
                                <input type="checkbox" name="services[]" id="customsoftwaredevelopment" value="Custom Software Development"/>
                                <label for="customsoftwaredevelopment" itemprop="name">
                                    Custom Software Development
                                </label> 
                                <input type="checkbox" name="services[]" id="qaservice" value="QA Service"/>
                                <label for="qaservice" itemprop="name">
                                    QA Service
                                </label> 
                                <input type="checkbox" name="services[]" id="digitalmarketing" value="Digital Marketing"/>
                                <label for="digitalmarketing" itemprop="name">
                                    Digital Marketing
                                </label> 
                                <input type="checkbox" name="services[]" id="codeaudit" value="Code Audit"/>
                                <label for="codeaudit" itemprop="name">
                                    Code Audit
                                </label> 
                                <input type="checkbox" name="services[]" id="otherservices" value="Other Services"/>
                                <label for="otherservices" itemprop="name">
                                    OtherServices
                                </label>
                                <p className="error-message" id="checkboxerror"></p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="fileupload-info">
                                Upload: .jpg, .png, .pdf, .csv, .xlsx, .doc, .docx file as
                                document.
                            </div>
                            <div className="inquiryBtn">
                                <div className="form-inline d-block">
                                    <div className="file-upload">
                                        <div className="custom-file"> 
                                            <input id="document" name="document[]" className="custom-file-input" label="document" aria-label="file" type="file" total-max-size="10485760" multiple=""/>
                                            <p className="custom-file-label">
                                                <i className="icon-plus"></i>
                                                Attach File
                                            </p>
                                        </div> 
                                        <span className="error-message" id="filesizeerror" style={{ display: 'none' }}>
                                            <label>
                                                File size must be less than 10
                                                MB.
                                            </label>
                                        </span> 
                                        <span className="error-message" id="fileerror" style={{ display: 'none' }}>
                                            <label>
                                                Please upload .jpg or .png or .pdf or .jpeg or
                                                .csv or .xlsx or .doc or .docx file as document.
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div id="files-area">
                                    <span id="filesList">
                                        <span id="files-names">

                                        </span>
                                    </span>
                                </div>
                                <div className="inquiryBtnOuter"> 
                                    <input type="text" id="phone-number" name="phone-number" value="" style={{ display: 'none' }}/> 
                                    <button className="primary-btn hvr-shutter-out-horizontal ml-lg-auto" id="contact-submit">
                                        Send 
                                        <FaArrowAltCircleRight className='banner-icon' />

                                        
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}
