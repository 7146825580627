import axios from 'axios';
import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      const data = response.data;
      return data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error:', error.response.status);
        // Optionally display the error message to the user
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in browser environments
        console.error('Error:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
      }
    }
  }
  return (
    <>
    <div className='notFound'>
      <div className='container text-center'>
      <img style={{objectFit:'contain',width:'50%'}} src={require('../assets/images/404.png')} />
      <h1>404 Not Found</h1>
      <p>The page you're looking for does not exist.</p>
      <div class="btn-container btn-blue">
                <Link to="/">Go to Home <FaArrowRight/></Link>
            </div>
      </div>
    </div>
    </>
  )
}
