import React from 'react'
import { CMsection } from "../../DesignInnerPages/GraphicDesign/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/GraphicDesign/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/GraphicDesign/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/GraphicDesign/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/GraphicDesign/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/GraphicDesign/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/GraphicDesign/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/GraphicDesign/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/GraphicDesign/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/GraphicDesign/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/GraphicDesign/InnerPgSec";
import Faqs from '../../DesignInnerPages/GraphicDesign/Faqs';
import { ContactForm } from '../../DesignInnerPages/GraphicDesign/ContactForm';

export const GraphicDesign = () => {
  return (
    <>
    <ServicePageBanner/>
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection/>
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
