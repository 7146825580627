import React from 'react'
import { CMsection } from '../../DesignInnerPages/UiUxComponent/CMsection'
import { WeOfferSection } from '../../DesignInnerPages/UiUxComponent/WeOfferSection'
import { LetDiscussSection } from '../../DesignInnerPages/UiUxComponent/LetDiscussSection'
import { WhatWeDoSection } from '../../DesignInnerPages/UiUxComponent/WhatWeDoSection'
import { EngagingSteps } from '../../DesignInnerPages/UiUxComponent/EngagingSteps'
import { DominExpertiseSec } from '../../DesignInnerPages/UiUxComponent/DominExpertiseSec'
import { TechnologyStackSection } from '../../DesignInnerPages/UiUxComponent/TechnologyStackSection'
import { RelatedBlogSection } from '../../DesignInnerPages/UiUxComponent/RelatedBlogSection'
import { CTASection } from '../../DesignInnerPages/UiUxComponent/CTASection'
import { ServicePageBanner } from '../../DesignInnerPages/UiUxComponent/ServicePageBanner'
import { InnerPgSec } from '../../DesignInnerPages/UiUxComponent/InnerPgSec'
import Faqs from '../../DesignInnerPages/UiUxComponent/Faqs'
import { ContactForm } from '../../DesignInnerPages/UiUxComponent/ContactForm'
export const UiUx = () => {
  return (
    <>
        <ServicePageBanner/>
        <CMsection/>
        {/* <InnerPgSec/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
    </>
  )
}
