import React from 'react';
import '../assets/css/ServicePage.css';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';


export const LetDiscussSection = () => {
  return (
    <>
        <div className="let-discuss mb-40">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="discuss-left">
                        <div className="discuss-title">
                            <p className="discuss-title-heading">Design Is An <span>Attractive</span> Aspect. But is it everything?</p>
                            <p className="mt-3"><i>Visual elements will always be the matter of first impression, but only
                                    good looks won’t be inclusive for digital transformation. To see your idea turning
                                    real onto the web, you will need good developers and strategists.</i></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="discuss-right left-brush">
                    <p>Want More</p><Link to="contact-us/"><button class="cmr-outline-btn hvr-shutter-out-horizontal">Let’s Develop<FaArrowAltCircleRight className='banner-icon' /></button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
