import React from 'react'
import '../assets/css/ServicePage.css';
import { BsCart4, BsFillUmbrellaFill, BsShieldCheck, BsBookshelf, BsTruck  } from "react-icons/bs";
import { FaHeartbeat, FaHandHoldingUsd, FaHome} from "react-icons/fa";
import { RiMovie2Line } from "react-icons/ri";
import { IoFootballOutline } from "react-icons/io5";
import { GiFruitBowl, GiWheat  } from "react-icons/gi";



export const DominExpertiseSec = () => {
  return (
    <>
        <section className="industires industires-new-sec px-30">
        <div className="container">
            <div className="row mt-3 mb-4 align-items-center">
                <div className="col-md-12">
                    <div className="sec-title">
                        <p><span>Power Your Industry</span></p>
                        <h3><span>Domain Expertise</span></h3>
                    </div>
                </div>
            </div>
            <div className="industires-infocus-boxes">
                <ul>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <BsCart4 className = {'dominIcon'}  /> </div>
                            <p>Ecommerce</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <FaHeartbeat className = {'dominIcon'}  /></div>
                            <p>Healthcare</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <RiMovie2Line className = {'dominIcon'}  /> </div>
                            <p>Media</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <FaHandHoldingUsd className = {'dominIcon'} /></div>
                            <p>Finance</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <BsFillUmbrellaFill className = {'dominIcon'} /> </div>
                            <p>Insurance</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <BsShieldCheck className = {'dominIcon'} /> </div>
                            <p>Security</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <BsBookshelf className = {'dominIcon'} /> </div>
                            <p>Education</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <FaHome className = {'dominIcon'} /> </div>
                            <p>Real Estate</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <IoFootballOutline className = {'dominIcon'} /> </div>
                            <p>Sports</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <BsTruck className = {'dominIcon'} /> </div>
                            <p>Logistic</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <GiWheat className = {'dominIcon'} /> </div>
                            <p>Agritech</p>
                        </a> </li>
                    <li> <a href="#">
                            <div className="infocus-box-img"> <GiFruitBowl className = {'dominIcon'} /> </div>
                            <p>Food</p>
                        </a> </li>
                </ul>
            </div>
        </div>
    </section>
    </>
  )
}
