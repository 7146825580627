import React from 'react'
import './AboutUs.css'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { MdOutlineStar, MdOutlineStarHalf } from 'react-icons/md'
import { Link } from 'react-router-dom'

export const AboutUsIntroSec = () => {
  return (
    <div className='introSec'>
        <MDBContainer>
            <MDBRow>
                <MDBCol lg="6" md="6">
                    <h2>
                        About Teals Lab
                    </h2>
                    <p>
                    Teals Lab was “born” out of the desire to bring the top and finest Android and iOS engineers from around the world together to develop specialized, unique, and technologically advanced mobile apps for the world’s leading brands, enterprises, startups, and businesses.<br/><br/>

We have the “Get Things Done” culture at our workplace. There are no excuses, no if’s or but’s in our dictionary. Committed to navigating the ship of creativity to create mobile solutions, we solve the real-life problems of our clients and their customers. Our passion for work has won us many awards, year after year.<br/><br/>

With development centers in India, Canada, and the United States, we take pride in building long-term relationships with our clients and working to help them attain their business goals.
                    </p>

                </MDBCol>
                <MDBCol lg="6">
                    <MDBRow>
                        <MDBCol lg='6'>
                            <div className='about-sec-box-1'>
                            <Link to="#">

                            <img  src={require('../assets/images/clutch-logo.png')} alt="logo" />
                            </Link>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStarHalf/><span style={{fontSize:'25px',fontWeight:'bold'}}>4.4</span>
                            <div className='box-para'>
                                <p>
                                Recognized as Top App Developers by Clutch.
                                </p>
                            </div>

                        </div>
                        </MDBCol>
                        <MDBCol lg='6'>
                            <div className='about-sec-box-2'>
                            <Link to="#">

                            <img  src={require('../assets/images/upwork-logo.png')} alt="logo" />
                            </Link>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStarHalf/><span style={{fontSize:'25px',fontWeight:'bold'}}>4.4</span>
                            <div className='box-para'>
                                <p>
                                Recognized as Top App Developers by Clutch.
                                </p>
                            </div>

                        </div>
                        </MDBCol>
                        <MDBCol lg='6'>
                            <div className='about-sec-box-3'>
                            <Link to="#">

                            <img  src='https://www.spaceotechnologies.com/wp-content/uploads/2023/01/Google_logo.svg' alt="logo" />
                            </Link>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStarHalf/><span style={{fontSize:'25px',fontWeight:'bold'}}>4.4</span>
                            <div className='box-para'>
                                <p>
                                Recognized as Top App Developers by Clutch.
                                </p>
                            </div>

                        </div>
                        </MDBCol>
                        <MDBCol lg='6'>
                            <div className='about-sec-box-4'>
                            <Link to="#">

                            <img  src='https://www.spaceotechnologies.com/wp-content/uploads/2023/01/glassdoor.svg' alt="logo" />
                            </Link>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStar/>
                            <MdOutlineStarHalf/><span style={{fontSize:'25px',fontWeight:'bold'}}>4.4</span>
                            <div className='box-para'>
                                <p>
                                Recognized as Top App Developers by Clutch.
                                </p>
                            </div>

                        </div>
                        </MDBCol>
                    </MDBRow>
                    
                </MDBCol>
            </MDBRow>
        </MDBContainer>
        
    </div>
  )
}
