import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import './Homeservices.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const AwardWinnerSec = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };
    const awimg=[
      {
        awimage: require('../assets/images/awardwinning1.webp')
      },
      {
        awimage: require('../assets/images/awardwinning2.webp')
      },
      {
        awimage: require('../assets/images/awardwinning3.webp')
      },
      {
        awimage: require('../assets/images/awardwinning4.webp')
      },
      {
        awimage: require('../assets/images/awardwinning5.webp')
      },
      {
        awimage: require('../assets/images/awardwinning6.webp')
      },
      {
        awimage: require('../assets/images/awardwinning7.webp')
      },
      {
        awimage: require('../assets/images/awardwinning8.webp')
      }
    ]
  
    const shortText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
    const fullText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
  
  return (
    <div className='awardPage'>
        <MDBContainer>
            <MDBRow>

                <MDBCol lg={12}>
                    <h2>
                    Award-winning digital marketing agency
                    </h2>
                </MDBCol>
                <MDBCol lg={12}>
                <p>
        {isExpanded ? fullText : shortText}
      </p>
      <button className='readmore' onClick={toggleReadMore}>
        {isExpanded ? 'Show Less' : 'Learn More...'}
      </button>
                </MDBCol>
                <Swiper
        className="carousel-1"
        spaceBetween={30}
        slidesPerView={2}
        loop={true}
        // pagination={{ clickable: true }}
        // navigation={true}
        modules={[  Autoplay]}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        }}
        >
          {awimg.map((awardimg, index) => (
            <SwiperSlide key={index} >
              <div className="review_lb">
                <div className="media gap-2">
                  <div className="cell-top">
                    <picture>
                      <img
                        decoding="async"
                        src={awardimg.awimage}
                        className="img-fluid lazyloaded"
                        alt="Client"
                        width="70"
                        height="70"
                      />
                    </picture>
                    
                  </div>
                </div>
                
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
            </MDBRow>
        </MDBContainer>
    </div>
  )
}

export default AwardWinnerSec