import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import './Homeservices.css';
import { Link } from 'react-router-dom';

export const RecentWork = () => {
    const imagecol = [
        {
            image: require('../assets/images/recentWorkImg.webp'),
            className: 'image-class-1'
        },
        {
            image: require('../assets/images/recentWorkImg.webp'),
            className: 'image-class-2'
        },
        {
            image: require('../assets/images/recentWorkImg.webp'),
            className: 'image-class-3'
        },
    ];
    const paracol = [
        {
            recentpara: '2500+',
            recentpara2: 'Project delivered',
            className: 'para-class-1'
        },
        {
            recentpara: '1600+',
            recentpara2: 'Happy Clients',
            className: 'para-class-2'
        },
        {
            workheading: 'Want to start a Project?',
            workbtn: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">Get Estimation</Link>,
            className: 'para-class-3'
        },
    ];
    
    return (
        <div className='recentWorkPage'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol lg={12} sm={12}>
                        <h2>
                            Our Recent Work
                        </h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </MDBCol>
                    {imagecol.map((workimage, index) => (
                        <MDBCol lg={4} key={index}>
                            <div className={`rwimage ${workimage.className}`}>
                                <img
                                    decoding="async"
                                    src={workimage.image}
                                    data-src={workimage.image}
                                    className="img-fluid lazyloaded"
                                />
                            </div>
                        </MDBCol>
                    ))}
                    {paracol.map((workpara, index) => (
                        <MDBCol lg={4} xs={4} key={index}>
                            <div className={`paraSec ${workpara.className}`}>
                                <h3>{workpara.workheading}</h3>
                                <div className="cta-button">
                                    {workpara.workbtn}
                                </div>
                                <p className='para1'>{workpara.recentpara}</p>
                                <p>{workpara.recentpara2}</p>
                            </div>
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBContainer>
        </div>
    );
};
