import React from 'react'
import { CMsection } from "../../Others/StrategyConsultingservices/CMsection";
import { WeOfferSection } from "../../Others/StrategyConsultingservices/WeOfferSection";
import { LetDiscussSection } from "../../Others/StrategyConsultingservices/LetDiscussSection";
import { WhatWeDoSection } from "../../Others/StrategyConsultingservices/WhatWeDoSection";
import { EngagingSteps } from "../../Others/StrategyConsultingservices/EngagingSteps";
import { DominExpertiseSec } from "../../Others/StrategyConsultingservices/DominExpertiseSec";
import { TechnologyStackSection } from "../../Others/StrategyConsultingservices/TechnologyStackSection";
import { RelatedBlogSection } from "../../Others/StrategyConsultingservices/RelatedBlogSection";
import { CTASection } from "../../Others/StrategyConsultingservices/CTASection";
import { ServicePageBanner } from "../../Others/StrategyConsultingservices/ServicePageBanner";
import { InnerPgSec } from "../../Others/StrategyConsultingservices/InnerPgSec";
import Faqs from '../../Others/StrategyConsultingservices/Faqs';
import { ContactForm } from '../../Others/StrategyConsultingservices/ContactForm';

export const StrategyConsultingservices = () => {
  return (
    <>
    <ServicePageBanner />
      <InnerPgSec/>
      {/* <CMsection /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
      </>
  )
}
