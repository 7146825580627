import React, { useState } from 'react'
import './portfolio.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

export const Hero = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        brief: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://crm.tealslab.com/api/customer', {
            ...formData,
            news: 1, 
            route: window.location.pathname, 
            brand: 'authorsbreeze', 
            tag: 'coupon pop form', 
            price: 'null', 
            domain: 'www.ecommonks.com' 
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(response.data); 
          
          setFormData({
            name: '',
            email: '',
            phone: '',
            brief: ''
          });
           // Redirect to thank you page after successful form submission
       navigate('/thank-you'); // Adjust the route to your thank you page

        } catch (error) {
          console.error('Error:', error);
        }
      };
  return (
    <>
    <section class="breadcrumbs_style tecknology">
                    <div class="h-100 container">
                        <div class="h-100 row">
                            <div class="col-12">
                              
                                <ul class="breadcrumb">
                                    <li>
                                        <Link to='/'> Home</Link>
                                    </li>
                                    <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                                    <li>
                                        <Link to='/portfolio'> Portfolio</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
    <div className="services_banner">
                    <div className="h-100 container">
                        <div className="h-100 row">
                            <div className="my-auto col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12" data-aos="fade-right">
                                <div className="banenr_content">
                                <div className="example" data-text="portfolio">
                    <h1>Passion-Fueled Innovation: Where Dreams Become Reality</h1>
                  </div>
                  <div className="banner_content_txt listing_flex">
                    <p>
                      <strong>TekRevol is not just a service provider; we are an invaluable extension of your marketing team, bringing a powerful combination of strategic thinking, expertise, and flawless execution to the areas where you need it the most. </strong>
                    </p>
                    <ul>
                      <li>Strategic Planning</li>
                      <li>Cutting-Edge Technologies</li>
                      <li>Unconventional App Experience</li>
                      <li>Creative Solutions</li>
                    </ul>
                  </div>
                                </div>
                            </div>
                            <div className="my-auto ms-auto col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12" data-aos="fade-left">
                                <div className="banner_form">
                                    <h2>
                                        Book a Free <span>Consultation</span>
                                    </h2>
                                    <form className="banner_form_inner" onSubmit={handleSubmit}>
                                        <div className="from_item">
                                            <input placeholder="Full Name" name="name" type="text" className="form-control" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <input placeholder="Email" name="email" type="email" className="form-control" value={formData.email} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <input type="tel" className="form-control" minLength="10" maxLength="12" placeholder="Phone Number *" name="phone" value={formData.phone} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <textarea  placeholder="Describe Your Project Need." className="form-control" rows="4" name="brief" value={formData.brief} onChange={handleChange} required></textarea>
                                        </div>
                                        <div className="from_item_check">
                                            <div>
                                                <div className="form-check">
                                                    <input name="nda" type="checkbox" id="check-api-checkbox" className="form-check-input" value="1" />
                                                    <label for="check-api-checkbox" className="form-check-label">Share Non Disclosure Agreement</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn_submit btn btn-primary">Get In Touch</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}
