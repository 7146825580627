import React from "react";
import "./AboutUs.css";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
export const AboutUsBanner = () => {
  return (
    <>
      <section
        class="section1 text-white"
        style={{
          backgroundImage:
            "url(https://www.spaceotechnologies.com/wp-content/uploads/2021/02/about-us-banner.png.webp)",
          }}
      >
        <div class="container-xl">
          <div class="row">
          <section class="breadcrumbs_style">
            <div class="h-100 container">
              <div class="h-100 row">
                <div class="col-12">
                  <ul class="breadcrumb contacts">
                    <li>
                      <Link to="/"> Home</Link>
                    </li>
                    <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                    <li>
                      <Link to="/about-us"> About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
            <div class="col-md-7 col-12">
              <h1 class="fonts-45 mb-3 decor-line text-left font-weight-normal">
                Get Things Done
              </h1>
              <p class="fonts-18 mb20" style={{ color: "white" }}>
                At Space-O Technologies, we believe in “Get Things Done”. Have
                an app or web based project in mind? Bring it to us. No matter
                how complex, we will find the best solution and complete it
                within a fixed time.
              </p>
            </div>
          
          </div>
        </div>
      </section>
    </>
  );
};
