import React, { useEffect, useState } from "react";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css";
export const AboutCustomize = () => {
    const slider = [
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
        {
          image: "https://www.spaceotechnologies.com/wp-content/uploads/2021/09/Enterprise-Mobility-Solution.jpg.webp",
          para: "On-demand Solution",
        },
      ];
      useEffect(() => {
        const flkty = new Flickity('.carousel', {
          cellAlign: 'center',
          pageDots: false,
          groupCells: '20%',
          selectedAttraction: 0.01,
          friction: 0.15,
          autoPlay: 7000, // Auto-play every 3 seconds
          wrapAround: true, // Enable looping
          imagesLoaded: true, // Ensure all images are loaded before initializing
        });
      
        return () => {
          flkty.destroy();
        };
      }, []);
      
  return (
    <>
      <section
        class="section4 py-4 text-center pt50 pb50"
        style={{background:'#f7f7f7'}}
      >
        <div class="small-container mb-5">
          <h2 class="mb-3 fonts-40 font-weight-bold decor-line text-center mt-0">
            Customized Business Solutions
          </h2>
          <p class="fonts-16 mxw-900">
            Irrespective of the type and size of your business, our certified
            and skilled developers are here to help you out. We provide
            solutions to your business problems through advanced mobile
            solutions. Here, we are great at offering the following solutions:
          </p>
        </div>
        <div class="container-xl text-center">
         <div className="carousel">
         {slider.map((image, index) => (
      <div className="carousel-cell" key={index}>
               
               <img
                 className="img-fluid"
                 style={{height:'100%',objectFit:'cover',width:'100%'}}
                 src={image.image}
                 alt=""
               />
          <p class="font-weight-bold"><a href="#" data-wpel-link="internal">{image.para}</a></p>
      </div>
      ))}
    </div>
        </div>
      </section>
    </>
  );
};
