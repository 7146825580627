import React from 'react'
import { ServicePageBanner } from '../CmsDevelopmentPageComponent/ServicePageBanner'
import { CMsection } from '../CmsDevelopmentPageComponent/CMsection'
import { WeOfferSection } from '../CmsDevelopmentPageComponent/WeOfferSection'
import { LetDiscussSection } from '../CmsDevelopmentPageComponent/LetDiscussSection'
import { WhatWeDoSection } from '../CmsDevelopmentPageComponent/WhatWeDoSection'
import { EngagingSteps } from '../CmsDevelopmentPageComponent/EngagingSteps'
import { ContactForm } from '../CmsDevelopmentPageComponent/ContactForm'
import { DominExpertiseSec } from '../CmsDevelopmentPageComponent/DominExpertiseSec'
import { TechnologyStackSection } from '../CmsDevelopmentPageComponent/TechnologyStackSection'
import { RelatedBlogSection } from '../CmsDevelopmentPageComponent/RelatedBlogSection'
import { CTASection } from '../CmsDevelopmentPageComponent/CTASection'
import Faqs from '../CmsDevelopmentPageComponent/Faqs'
import { InnerPgSec } from '../CmsDevelopmentPageComponent/InnerPgSec'

export const CmsDevelopment = () => {
  return (
    <>
    <ServicePageBanner/>
        <InnerPgSec/>
        {/* <CMsection/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
    </>
  )
}
