import React from 'react';
import '../assets/css/ServicePage.css';


export const WeOfferSection = () => {
  return (
    <>
        <div className="container mini-container px-30">
        <div className="sec-tag-lines"><span>What We Offer</span>
            <h3>Let CMARIX help you grow with most sophisticated business intelligence software</h3>
        </div>
        <div className="sec-description mt-3">
            <p>As a leading <strong>BI company in India</strong>, CMARIX is widely acclaimed for its huge exposure into
                the development and implementation of BI software and tools across enterprises of all types and niches.
                The company boasts of a large pool of BI talents versed in business intelligence <a href="#" title="software development">software development</a>, latest skill
                skills and industry practices and strategic BI planning.</p>
            <p>At CMARIX, we help businesses to get through the tough competition with our highly customized and
                business centric BI development services. We create BI strategy, develop BI tools and come with BI
                strategy based on the target audience and unique business requirements and attributes.</p>
            <p>We boast a robust list of BI solutions that we have created and implemented for years across multiple
                industries and a plethora of startups who have been benefited tremendously by our strategic BI
                consultation and planning. We help you rave your path to success with our expert strategic BI
                consultancy, powerful BI software solutions and tools, expertise driven BI training and development and
                BI integration services.</p>
        </div>
    </div>
    </>
  )
}