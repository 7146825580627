import React from 'react'
import { CMsection } from "../../DesignInnerPages/NFTDesign/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/NFTDesign/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/NFTDesign/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/NFTDesign/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/NFTDesign/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/NFTDesign/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/NFTDesign/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/NFTDesign/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/NFTDesign/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/NFTDesign/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/NFTDesign/InnerPgSec";
import Faqs from '../../DesignInnerPages/NFTDesign/Faqs';
import { ContactForm } from '../../DesignInnerPages/NFTDesign/ContactForm';

export const NFTDesign = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
