import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./components/screens/Home";
import TopNav from "./components/screens/TopNav";
import { Footer } from "./components/screens/Footer";
import { Portfolio } from "./components/screens/Portfolio";
import { FooterBrandings } from "./components/screens/FooterBrandings";
import { Technologies } from "./components/screens/Technologies";
import { ServicesPage } from "./components/screens/ServicesPage";
import { UiUx } from "./components/screens/DesignInnerScreens/UiUx";
import { useEffect } from "react";
import { MobileApps } from "./components/screens/DesignInnerScreens/MobileApps";
import { ContactUs } from "./components/screens/ContactUs";
import { About } from "./components/screens/About";
// import { Testimonials } from "./components/screens/Testimonials";
import '../node_modules/video-react/dist/video-react.css'
import { GraphicDesign } from "./components/screens/DesignInnerScreens/GraphicDesign";
import { BrandAndIdentity } from "./components/screens/DesignInnerScreens/BrandAndIdentity";
import { LogoDesign } from "./components/screens/DesignInnerScreens/LogoDesign";
import { NFTDesign } from "./components/screens/DesignInnerScreens/NFTDesign";
import { AudioVideoProd } from "./components/screens/DesignInnerScreens/AudioVideoProd";
import { DigitalMarketing } from "./components/screens/DigitalMarketing";
import { PPC } from "./components/screens/DigitalMarketingScreen/PPC";
import { SEO } from "./components/screens/DigitalMarketingScreen/SEO";
import { SMM } from "./components/screens/DigitalMarketingScreen/SMM";
import { EmailMarketting } from "./components/screens/DigitalMarketingScreen/EmailMarketting";
import { ContentMarketting } from "./components/screens/DigitalMarketingScreen/ContentMarketting";
import { GoogleTagManager } from "./components/screens/DigitalMarketingScreen/GoogleTagManager";
import { IOSApps } from "./components/screens/MobileAppDevelopment/IOSApps";
import { AndroidApps } from "./components/screens/MobileAppDevelopment/AndroidApps";
import { MobileApp } from "./components/screens/MobileApp";
import { CrossPlatform } from "./components/screens/MobileAppDevelopment/CrossPlatform";
import { NotFound } from "./components/screens/NotFound";
import { WebDevelopement } from "./components/screens/WebDevelopement";
import { FrontEndDevelopment } from "./components/screens/WebDevelopment/FrontEndDevelopment";
import { BackEndDevelopment } from "./components/screens/WebDevelopment/BackEndDevelopment";
import { WebAppDevelopment } from "./components/screens/WebDevelopment/WebAppDevelopment";
import { WebPortalDevelopment } from "./components/screens/WebDevelopment/WebPortalDevelopment";
import { DrupalDevelopment } from "./components/screens/CMSDevelopment/DrupalDevelopment";
import { MagentoDevelopment } from "./components/screens/CMSDevelopment/MagentoDevelopment";
import { WixDevelopment } from "./components/screens/CMSDevelopment/WixDevelopment";
import { WordPressManagement } from "./components/screens/CMSDevelopment/WordPressManagement";
import { SoftwareDevelopmentServices } from "./components/screens/Others/SoftwareDevelopmentServices";
import { OnDemandAppDevelopment } from "./components/screens/Others/OnDemandAppDevelopment";
import { OTTAppDevelopmentServices } from "./components/screens/Others/OTTAppDevelopmentServices";
import { APIIntegrationServices } from "./components/screens/Others/APIIntegrationServices";
import { StrategyConsultingservices } from "./components/screens/Others/StrategyConsultingservices";
import { StaffAugmentation } from "./components/screens/Others/StaffAugmentation";
import { GameDevelopment } from "./components/screens/Others/GameDevelopment";
import { CmsDevelopment } from "./components/screens/CmsDevelopment";
import { ThankYou } from "./components/screens/ThankYou";
import ScrollToTop from "./components/ScrollComp/ScrollToTop";
import useScrollRestoration from "./components/ScrollComp/useScrollRestoration";

function App() {
  useScrollRestoration(); // Use the custom hook
  return (
    <>
      <TopNav />
      <ScrollToTop/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/portfolio" element={<Portfolio />} />
        {/* design services */}
          <Route path="/services/design" element={<ServicesPage />} />
            <Route path="/services/design/ui-ux" element={<UiUx />} />
            <Route path="/services/design/mobile-apps" element={<MobileApps />} />
            <Route path="/services/design/graphic-design" element={<GraphicDesign/>} />
            <Route path="/services/design/brand-identity" element={<BrandAndIdentity/>} />
            <Route path="/services/design/logo-design" element={<LogoDesign/>} />
            <Route path="/services/design/nft-design" element={<NFTDesign/>} />
            <Route path="/services/design/audio-video-production" element={<AudioVideoProd/>} />
        {/* design services End */}
        {/* Digital Marketing */}
          <Route path="/services/digital-marketing" element={<DigitalMarketing/>} />
            <Route path="/services/digital-marketing/ppc" element={<PPC/>} />
            <Route path="/services/digital-marketing/seo" element={<SEO/>} />
            <Route path="/services/digital-marketing/smm" element={<SMM/>} />
            <Route path="/services/digital-marketing/email-marketing" element={<EmailMarketting/>} />
            <Route path="/services/digital-marketing/content-marketing" element={<ContentMarketting/>} />
            <Route path="/services/digital-marketing/google-tag-manager" element={<GoogleTagManager/>} />
        {/* Digital Marketing End */}
        {/* App Development */}
          <Route path="/services/mobile-app-development" element={<MobileApp/>} />
            <Route path="/services/mobile-app-development/ios-mobile-app" element={<IOSApps/>} />
            <Route path="/services/mobile-app-development/android-mobile-app" element={<AndroidApps/>} />
            <Route path="/services/mobile-app-development/cross-platform" element={<CrossPlatform/>} />
        {/* App Development End */}
        {/* Web Developement  */}
        <Route path="/services/web-developement" element={<WebDevelopement/>} />
          <Route path="/services/web-developement/front-enddevelopment" element={<FrontEndDevelopment/>} />
          <Route path="/services/web-developement/back-end" element={<BackEndDevelopment/>} />
          <Route path="/services/web-developement/web-app" element={<WebAppDevelopment/>} />
          <Route path="/services/web-developement/web-portal" element={<WebPortalDevelopment/>} />
        {/* Web Developement End */}
        {/* CMS Developement  */}
        <Route path="/services/cms-development" element={<CmsDevelopment/>} />
          <Route path="/services/cms-development/magento" element={<MagentoDevelopment/>} />
          <Route path="/services/cms-development/wix" element={<WixDevelopment/>} />
          <Route path="/services/cms-development/drupal" element={<DrupalDevelopment/>} />
          <Route path="/services/cms-development/wordpress" element={<WordPressManagement/>} />
        {/* CMS Developement End */}
        {/* Other Services  */}
        <Route path="/services/software-development-services" element={<SoftwareDevelopmentServices/>} />
          <Route path="/services/ott-app-development-services" element={<OTTAppDevelopmentServices/>} />
          <Route path="/services/On-Demand-App-Development" element={<OnDemandAppDevelopment/>} />
          <Route path="/services/api-Integration-services" element={<APIIntegrationServices/>} />
          <Route path="/services/strategy-consulting-services" element={<StrategyConsultingservices/>} />
          <Route path="/services/staff-augmentation" element={<StaffAugmentation/>} />
          <Route path="/services/Game-development" element={<GameDevelopment/>} />
        {/* Other Services End */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<About />} />
        {/* <Route path="/testimonial" element={<Testimonials />} /> */}
        <Route path="*" element={<NotFound/>} />
        <Route path="/thank-you" element={<ThankYou/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
