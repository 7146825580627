import React, { useState } from 'react';
import './Homeservices.css';
import { MDBAccordion, MDBAccordionItem, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { RiTeamLine } from 'react-icons/ri';

const hideLiContents = [
  {  text: 'Content for hide-li 1', hideLiHeading:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ' },
  {  text: 'Content for hide-li 2', hideLiHeading:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. " },
  {  text: 'Content for hide-li 3', hideLiHeading:'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.' },
  {  text: 'Content for hide-li 4', hideLiHeading:'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' },
  {  text: 'Content for hide-li 5', hideLiHeading:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ' },
  {  text: 'Content for hide-li 6', hideLiHeading:'The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ' },
];

export const WhyChooseUs = () => {
    const acdata = [
        {  Acctext: 'Content for hide-li 1', AccHeading:'heading 1' },
        {  Acctext: 'Content for hide-li 2', AccHeading:'heading 2' },
        {  Acctext: 'Content for hide-li 3', AccHeading:'heading 3' },
        {  Acctext: 'Content for hide-li 4', AccHeading:'heading 4' },
        {  Acctext: 'Content for hide-li 5', AccHeading:'heading 5' },
        {  Acctext: 'Content for hide-li 6', AccHeading:'heading 6' },
      ];
  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  return (
    <div className='whUs px-30'>
        <MDBContainer>
            <MDBRow>
                <MDBCol sm={12}>
                    <h2>
                        Elevating Online Retail with Expert eCommerce Software Development
                    </h2>
                    <p>
                        Rise to the forefront of the eCommerce sector with CMARIX, where our unparalleled expertise fuses with innovative strategy to deliver first-class eCommerce software solutions.
                    </p>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
        <div className='desktop-sec'>

            <MDBContainer>
                <MDBRow>
                <ul className='position-relative'>
                    <li className={`first-li ${hoverIndex !== null ? 'hide' : ''}`}>
                    <div>
                        <img src={require('../assets/images/CLUTCH.webp')} alt='CLUTCH' />
                    </div>
                    </li>
                    {hideLiContents.map((content, index) => (
                    <li
                        key={index}
                        className='other-li'
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div>
                        <RiTeamLine />
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </li>
                    ))}
                    {hoverIndex !== null && (
                    <li className='hide-li position-absolute'>
                        <div >
                        <p>
                            {hideLiContents[hoverIndex].hideLiHeading}
                            </p>
                        <p>{hideLiContents[hoverIndex].text}</p>
                        </div>
                    </li>
                    )}
                </ul>
                </MDBRow>
            </MDBContainer>
        </div>
        <div className='mobile-sec'>

            <MDBContainer>
                <MDBRow>
                {acdata.map((paradata, index) => (
                    <MDBAccordion  key={index}>
                        <MDBAccordionItem collapseId={1} headerTitle={paradata.AccHeading}>
                        {paradata.Acctext}

                        </MDBAccordionItem>
                    </MDBAccordion>
                    ))}
                </MDBRow>
            </MDBContainer>
        </div>
    </div>
  );
};
