import React from 'react'
import { CMsection } from "../../WebDevelopment/WebApp/CMsection";
import { WeOfferSection } from "../../WebDevelopment/WebApp/WeOfferSection";
import { LetDiscussSection } from "../../WebDevelopment/WebApp/LetDiscussSection";
import { WhatWeDoSection } from "../../WebDevelopment/WebApp/WhatWeDoSection";
import { EngagingSteps } from "../../WebDevelopment/WebApp/EngagingSteps";
import { DominExpertiseSec } from "../../WebDevelopment/WebApp/DominExpertiseSec";
import { TechnologyStackSection } from "../../WebDevelopment/WebApp/TechnologyStackSection";
import { RelatedBlogSection } from "../../WebDevelopment/WebApp/RelatedBlogSection";
import { CTASection } from "../../WebDevelopment/WebApp/CTASection";
import { ServicePageBanner } from "../../WebDevelopment/WebApp/ServicePageBanner";
import { InnerPgSec } from "../../WebDevelopment/WebApp/InnerPgSec";
import Faqs from '../../WebDevelopment/WebApp/Faqs';
import { ContactForm } from '../../WebDevelopment/WebApp/ContactForm';

export const WebAppDevelopment = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
