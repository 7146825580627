import React from 'react'

export const FooterBrandings = () => {
    const officelocation=[
        {
            flag: require('../assets/images/usa.webp'),
            locationpara:'A346, Block 15 Gulistan-e-Johar, Karachi, Sindh 74600',
            flagName:'USA'
            
            
        },
        {
            flag: require('../assets/images/UAEFLAG.webp'),
            flagName:'UAE',
            locationpara:'A346, Block 15 Gulistan-e-Johar, Karachi, Sindh 74600'
            
            
        },
        {
            flag: require('../assets/images/usa.webp'),
            locationpara:'A346, Block 15 Gulistan-e-Johar, Karachi, Sindh 74600',
            flagName:'Pakistan'
            
            
        }
    ]
  return (
    <>
    <div className="footer-container form-inquiry ">
        {/* <div className="container">
            <div className="row"> */}
                <div className="cmarix-locations">
                    {/* <div className="footer-main-title">TealsLabs Pvt. Ltd.</div> */}
                    <div className="row footer_location_list">
                        {officelocation.map((loca,index)=>(
                    
                        <div className="col-lg-4 col-md-12 usa-loc" key={index}>
                            
                            <span className="map-icon">
                                <img src={loca.flag} 
                                alt="USA flag"
                                width='50'
                                height='50'
                                 />
                            </span >
                            <span className="footer-loaction-title">
                            {loca.flagName}
                            </span>

                            <p className="footer-loaction-address">
                                {loca.locationpara}
                            </p>
                            <p></p>
                        </div>
                    ))}
                        
                        
                    </div>
                </div>
            {/* </div>
        </div> */}
        {/* <div className="gmb-icons container">
            <div className="row">
                <div className='col-lg col-4'>
                <img style={{objectFit:'contain'}} src={require('../assets/images/CLUTCH.webp')} alt="Google Partner" />
                </div>
                <div className='col-lg col-4'>
                <img style={{objectFit:'contain'}} src={require('../assets/images/brand-Trustpilot.webp')} alt="Google Partner" />
                </div>
                <div className='col-lg col-4'>
                <img style={{objectFit:'contain'}} src={require('../assets/images/GMB-Logo.webp')} alt="Google Partner" />
                </div>
                
            </div>
        </div> */}
    </div>
    </>
  )
}
