import React from 'react'
import { CMsection } from "../../Others/APIIntegrationServices/CMsection";
import { WeOfferSection } from "../../Others/APIIntegrationServices/WeOfferSection";
import { LetDiscussSection } from "../../Others/APIIntegrationServices/LetDiscussSection";
import { WhatWeDoSection } from "../../Others/APIIntegrationServices/WhatWeDoSection";
import { EngagingSteps } from "../../Others/APIIntegrationServices/EngagingSteps";
import { DominExpertiseSec } from "../../Others/APIIntegrationServices/DominExpertiseSec";
import { TechnologyStackSection } from "../../Others/APIIntegrationServices/TechnologyStackSection";
import { RelatedBlogSection } from "../../Others/APIIntegrationServices/RelatedBlogSection";
import { CTASection } from "../../Others/APIIntegrationServices/CTASection";
import { ServicePageBanner } from "../../Others/APIIntegrationServices/ServicePageBanner";
import { InnerPgSec } from "../../Others/APIIntegrationServices/InnerPgSec";
import Faqs from '../../Others/APIIntegrationServices/Faqs';
import { ContactForm } from '../../Others/APIIntegrationServices/ContactForm';

export const APIIntegrationServices = () => {
  return (
    <>
    <ServicePageBanner />
      {/* <InnerPgSec/> */}
      <CMsection />
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
