import React from 'react'
import '../../assets/css/ServicePage.css';

import { MdOutlineContentPaste } from "react-icons/md";

export const CMsection = () => {
  return (
    <>
        <div class="insideedge px-30">
        <div class="container">
            <div class="cmr-reflectors">SERVICES</div>
            <div class="row mb-5 align-items-end">
                <div class="col-md-5">
                    <div class="sec-title">
                        <p>
                            <span>
                                We Provide
                            </span>
                        </p>
                        <h3 class="services-heading">
                            Our Content Marketing Services
                        </h3>
                    </div>
                </div>
                <div class="col-md-7">
                    <p class="right-tag-lines">
                        <strong>Next-Gen</strong> Content Marketing Beside <strong>Professional Excellence</strong>
                    </p>
                </div>
            </div>
            <div class="row justify-content-center custom-gap-20 pt-30">
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Content Strategy
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Content Creation
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Content Promotion
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            SEO Content Writing Services
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Management And Scheduling
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Performance Tracking
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Content Optimization
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div class="ser-plate">
                        <MdOutlineContentPaste className="CMicons" />
                        <p class="ser-title">
                            Email Marketing
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
