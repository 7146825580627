import React, { useState } from 'react'
import './TechBanner.css'
import { TechLanguage } from '../TechLanguage'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
export const TechBanner = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        brief: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://crm.tealslab.com/api/customer', {
            ...formData,
            news: 1, 
            route: window.location.pathname, 
            brand: 'authorsbreeze', 
            tag: 'coupon pop form', 
            price: 'null', 
            domain: 'www.ecommonks.com' 
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(response.data); 
          
          setFormData({
            name: '',
            email: '',
            phone: '',
            brief: ''
          });
           // Redirect to thank you page after successful form submission
       navigate('/thank-you'); // Adjust the route to your thank you page

        } catch (error) {
          console.error('Error:', error);
        }
      };
  return (
    <>
        <div id="___gatsby">
        <div style={{outline:'none'}} tabindex="-1" id="gatsby-focus-wrapper">
            <div className="main_container" id="middle-section">
            <section class="breadcrumbs_style tecknology">
                    <div class="h-100 container">
                        <div class="h-100 row">
                            <div class="col-12">
                                <ul class="breadcrumb">
                                    <li>
                                    <Link to='/'> Home</Link>
                                    </li>
                                    <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                                    <li>
                                    <Link to='/technologies'> Technologies</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="services_banner">
                    <div className="h-100 container">
                        <div className="h-100 row">
                            <div className="my-auto col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12" data-aos="fade-right">
                                <div className="banenr_content">
                                    <div className="example" data-text="Technologies">
                                        <h1>Revolutionary Technologies for a Changing World</h1>
                                    </div>
                                    <div className="banner_content_txt listing_flex">
                                        <p>
                                            <strong>At Teals, we harness the power of revolutionary technologies, employing a diverse range of programming languages and platforms to create custom solutions that perfectly align with the unique needs of our clients.</strong>
                                        </p>
                                        <ul>
                                            <li>Emerging technology adoption</li>
                                            <li>Cross-platform compatibility</li>
                                            <li>Agile development process</li>
                                            <li>Scalable infrastructure management</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="my-auto ms-auto col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12" data-aos="fade-left">
                                <div className="banner_form">
                                    <h2>
                                        Book a Free <span>Consultation</span>
                                    </h2>
                                    <form className="banner_form_inner" onSubmit={handleSubmit}>
                                        <div className="from_item">
                                            <input placeholder="Full Name" name="name" type="text" className="form-control" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <input placeholder="Email" name="email" type="email" className="form-control" value={formData.email} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <input type="tel" className="form-control" minLength="10" maxLength="12" placeholder="Phone Number *" name="phone" value={formData.phone} onChange={handleChange} required />
                                        </div>
                                        <div className="from_item">
                                            <textarea  placeholder="Describe Your Project Need." className="form-control" rows="4" name="brief" value={formData.brief} onChange={handleChange} required></textarea>
                                        </div>
                                        <div className="from_item_check">
                                            <div>
                                                <div className="form-check">
                                                    <input name="nda" type="checkbox" id="check-api-checkbox" className="form-check-input" value="1" />
                                                    <label for="check-api-checkbox" className="form-check-label">Share Non Disclosure Agreement</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn_submit btn btn-primary">Get In Touch</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <TechLanguage/>
                <div className="Teals_partner Teals_gray_bg p-100">
                    <div className="h-100 container">
                        <div className="h-100 row">
                            <div className="Teals_partner_title col-xxl-11 col-xl-11 col-lg-12 col-md-12 col-12">
                                <div className="example" data-text="Clientele">
                                    <h2>Our Clients</h2>
                                </div>
                                <p>We contribute to these partnership programs, supported by global technology experts. Partnering with industry leaders, we offer quality technology solutions that meet your business needs.</p>
                            </div>
                            <div className="Teals_partner_logo col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="row item_logo">
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-14.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-13.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-12.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-11.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-10.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-9.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-8.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-7.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-6.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-5.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-4.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-2.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-1.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-15.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-16.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-17.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-18.webp')} />
                                </div>
                                <div className="col-lg-2 col-6 logo_box">
                                    <img style={{objectFit:'contain'}} src={require('../../assets/images/partner-1.webp')} />
                                </div>
                                </div>
                                <div className="item_logo_slide">
                                    <div className="react-multi-carousel-list  carousel_featured" dir="ltr">
                                        <ul className="react-multi-carousel-track " style={{transition:'none',overflow:'unset',transform:'translate3d(0px,0,0)'}}></ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
    </>
  )
}
