import React from "react";

export const AboutSpecialize = () => {
  return (
    <>
      <section class="section3 pt0 pb30 text-center mt-4">
        <div class="container-xl">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="mb-3 fonts-40 font-weight-bold decor-line text-center mt-0">
                Services We Specialise In
              </h2>
              <p class="mb-5 mxw-900">
                The technological world is moving at a very fast pace and we
                have always kept up with it. Our top-notch developers are
                philomaths and have proven their expertise in these areas:
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/mobile-app-development.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/mobile-app-development.png.webp"
                    alt="Mobile App Development"
                    width="44"
                    height="79"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">
                  <a href="https://www.spaceotechnologies.com/services/mobile-app-development/">
                    Mobile App Development
                  </a>
                </p>
                <p>
                  We cover the entire app development cycle from conception to
                  deployment. We have served clients such as Nike, McAfee, and
                  Ferrari. We are the developers behind startups like Glovo and
                  Bevy, and over 4400 mobile apps for Android, iOS, and Web
                  platforms.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/iphone-app-development.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/iphone-app-development.png.webp"
                    alt="iPhone App Development"
                    width="70"
                    height="81"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">
                  <a href="#">iPhone App Development</a>
                </p>
                <p>
                  We make use of modern programming languages like Swift and
                  Objective-C to offer high-end iPhone app development services.
                  With proven technologies and approaches, we have developed
                  more than 2800 iPhone apps of various categories.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/android-app-development-1.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/android-app-development-1.png.webp"
                    alt="Android App Development"
                    width="71"
                    height="81"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">
                  <a href="https://www.spaceotechnologies.com/services/android-app-development/">
                    Android App Development
                  </a>
                </p>
                <p>
                  We have successfully developed 1600 Android apps of various
                  categories, including on-demand, health &amp; fitness and
                  other top apps categories. Using Java and Kotlin, we build
                  Android apps from scratch for Android devices to smartwatches
                  and smart TVs.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/ui-ux-design-1.png"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/ui-ux-design-1.png"
                    alt="UI/UX Design"
                    width="73"
                    height="80"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">UI/UX Design</p>
                <p>
                  Our team of designers use Adobe Photoshop, Balsamiq, or Sketch
                  to create engaging app designs. They develop visual elements,
                  create wireframes and prototypes, work on animations between
                  screens, and test the design for optimum functionality.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/ipad-app-development.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/ipad-app-development.png.webp"
                    alt="iPad App Development"
                    width="62"
                    height="79"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">
                  <a href="https://www.spaceotechnologies.com/services/ipad-app-development/">
                    iPad App Development
                  </a>
                </p>
                <p>
                  Our iPad app developers have knowledge of current iPad app
                  frameworks and application development process, from idea
                  validation to product launch. They develop and design
                  customized iPad apps, following the guidelines by the Apple
                  App Store.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-about autoheight" style={{height: '466px'}}>
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/web-app-development.png"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/web-app-development.png"
                    alt="Web App Development"
                    width="84"
                    height="78"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-20 font-weight-bold mb-3 mt-3">
                  <a href="https://www.spaceotechnologies.com/services/web-application-development/">
                    Web App Development
                  </a>
                </p>
                <p>
                  We design and develop custom web applications for different
                  industries. We offer end-to-end custom web app development
                  services including technology consulting, custom app
                  development, eCommerce solutions, CMS web apps, and web portal
                  development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
