import React from 'react';
import '../../assets/css/ServicePage.css';
import { FaArrowAltCircleRight } from 'react-icons/fa';


export const RelatedBlogSection = () => {
    const director3 = [
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },

    ]
  return (
    <>
        <div class="blog_section">
        <div class="container">
            <div class="blog_upper mb-4">
                <div class="upper_left">
                    <h4 class="blog_section_title">Our Blogs</h4>
                </div>
                <div class="upper_right"> <a href="#" target="_blank" class="view_all_blog">View All Blogs <FaArrowAltCircleRight className='banner-icon' /></a> </div>
            </div>
            <div class="blog_list">
                <div class="row">
                    {director3.map((directors,index)=>(
                        
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                        <div class="blog_item">
                            <picture class="blog_image">
                                <img class=" ls-is-cached lazyloaded" src={require('../../assets/images/best-algolia-search-for-eCommerce.webp')} data-src={require('../../assets/images/best-algolia-search-for-eCommerce.webp')} alt="Algolia Search For eCommerce – Challenges, Solutions, And Implement" height="242" width="450"/> 
                            </picture>
                            <p class="blog_category">{directors.cate}</p>
                            <p class="post-title">
                                <a href="#" target="_blank">
                                    {directors.para}
                                </a>
                                </p>
                                <span class="blog_date">
                                    {directors.dte}
                                </span>
                        </div>
                    </div>
                        ))}
                </div>
            </div>
        </div>
    </div>

    </>
  )
}
