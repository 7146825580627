import React from 'react'
import { ServicePageBanner } from '../ServicePagesComponents/ServicePageBanner'
import { CMsection } from '../ServicePagesComponents/CMsection'
import { WeOfferSection } from '../ServicePagesComponents/WeOfferSection'
import { LetDiscussSection } from '../ServicePagesComponents/LetDiscussSection'
import { WhatWeDoSection } from '../ServicePagesComponents/WhatWeDoSection'
import { EngagingSteps } from '../ServicePagesComponents/EngagingSteps'
import { ContactForm } from '../ServicePagesComponents/ContactForm'
import { DominExpertiseSec } from '../ServicePagesComponents/DominExpertiseSec'
import { TechnologyStackSection } from '../ServicePagesComponents/TechnologyStackSection'
import { RelatedBlogSection } from '../ServicePagesComponents/RelatedBlogSection'
import { CTASection } from '../ServicePagesComponents/CTASection'
import Faqs from '../ServicePagesComponents/Faqs'
import { InnerPgSec } from '../ServicePagesComponents/InnerPgSec'

export const ServicesPage = () => {
  return (
    <>
        <ServicePageBanner/>
        <InnerPgSec/>
        {/* <CMsection/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
        
    </>
  )
}
