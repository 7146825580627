import React from 'react'
import { CMsection } from "../../DesignInnerPages/AudioVideoService/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/AudioVideoService/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/AudioVideoService/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/AudioVideoService/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/AudioVideoService/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/AudioVideoService/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/AudioVideoService/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/AudioVideoService/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/AudioVideoService/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/AudioVideoService/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/AudioVideoService/InnerPgSec";
import Faqs from '../../DesignInnerPages/AudioVideoService/Faqs';
import { ContactForm } from '../../DesignInnerPages/AudioVideoService/ContactForm';

export const AudioVideoProd = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
