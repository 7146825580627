import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { FiSmartphone } from "react-icons/fi";
import { BiMinus, BiPlus, BiSolidJoystick } from "react-icons/bi";
import { LiaLaptopCodeSolid } from "react-icons/lia";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";


function TopNav() {
  const [isSticky, setIsSticky] = useState(false);
  const [showServicesBox, setShowServicesBox] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(null); // Single state to manage open/close status

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleServicesBox = () => {
    setShowServicesBox((prevShowServicesBox) => !prevShowServicesBox);
  };

  const closeMenu = () => {
    setShowServicesBox(false);
    setMenuExpanded(false);
    setActiveSubMenu(null);
    setIsMobileMenuOpen(null);
  };

  const handleMobileMenuToggle = (menuId) => {
    setIsMobileMenuOpen((prevMenuId) => (prevMenuId === menuId ? null : menuId));
  };

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Navbar
      expand="lg"
      className={`bg-body-tertiary ${isSticky ? "sticky-top" : ""}`}
      expanded={menuExpanded}
    >
      <Container className="px-4">
        <Navbar.Brand href="#">
          <Link to="/" onClick={closeMenu}>
            <img width={150} src={require("../assets/logo/Logo.png")} data-src={require("../assets/logo/Logo.png")} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" onClick={() => {
          setMenuExpanded(!menuExpanded);
          if (menuExpanded) {
            setIsMobileMenuOpen(null); // Close all submenus when closing the main menu
          }
        }} />
                <Navbar.Collapse id="navbarScroll" style={{ justifyContent: "end" }}>
          <Nav
            className="my-2 my-lg-0"
            navbarScroll
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Nav.Link
              className={`hoverlink none-for-res ${showServicesBox ? 'additional-class' : ''}`}
              onClick={toggleServicesBox}
              onMouseEnter={() => setShowServicesBox(true)}
              onMouseLeave={() => setShowServicesBox(false)}
            >
              Services
            </Nav.Link>
            <Nav.Link
              className={`hoverlink none-for-web ${showServicesBox ? 'additional-class' : ''}`}
              onClick={toggleServicesBox}
              // onMouseEnter={() => setShowServicesBox(true)}
              // onMouseLeave={() => setShowServicesBox(false)}
            >
              Services
            </Nav.Link>
            {showServicesBox && (
              <div
              className="full-width-box"
              onMouseEnter={() => setShowServicesBox(true)}
              onMouseLeave={() => setShowServicesBox(false)}
              >
                <div className="menu_container">
                  <div className="menu_items">
                    <div className="row p-3">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12 resp-submenu none-for-res">
                        {" "}
                        <span className="menu_type under_line">
                          {" "}
                          <Link>Our Services</Link>
                        </span>
                        <img
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          src={require("../assets/images/ebook_header.webp")}
                          alt=""
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        {" "}
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link
                              className={currentPath === "/services/design" ? "active" : ""}
                              onClick={closeMenu}
                              to="/services/design"
                            >
                              Design
                            </Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("design")}
                            >
                              {isMobileMenuOpen === "design" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        {/* res menu */}
                        <div className={`submenu-container ${isMobileMenuOpen === "design" ? "open" : ""}`}>
                          <ul className="row sub-menu-navbar menu-item-list pl-0 column-2" id="development-design">
                            <li>
                              <Link className={currentPath === "/services/design/ui-ux" ? "active" : ""} onClick={closeMenu} to="/services/design/ui-ux">
                                UI/UX Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/mobile-apps" ? "active" : ""} onClick={closeMenu} to="/services/design/mobile-apps">
                                Mobile App Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/graphic-design" ? "active" : ""} onClick={closeMenu} to="/services/design/graphic-design">
                                Graphic Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/brand-identity" ? "active" : ""} onClick={closeMenu} to="/services/design/brand-identity">
                                Brand &amp; Identity Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/logo-design" ? "active" : ""} onClick={closeMenu} to="/services/design/logo-design">
                                Logo Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/nft-design" ? "active" : ""} onClick={closeMenu} to="/services/design/nft-design">
                                NFT Design
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/design/audio-video-production" ? "active" : ""} onClick={closeMenu} to="/services/design/audio-video-production">
                                Audio/Video Production Service
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        {/* res menu */}
                        {/* desktop menu */}
                        <ul
                          className="row sub-menu-navbar menu-item-list  pl-0 column-2 none-for-res"
                          id="development-design"
                        >
                          <li>
                            <Link className={currentPath === "/services/design/ui-ux" ? "active" : ""} onClick={closeMenu} to="/services/design/ui-ux">
                              {" "}
                              UI/UX Design
                            </Link>
                            <FaArrowCircleRight />

                          </li>
                          <li>
                            <Link className={currentPath === "/services/design/mobile-apps" ? "active" : ""} onClick={closeMenu} to="/services/design/mobile-apps">
                              Mobile App Design
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/design/graphic-design" ? "active" : ""} onClick={closeMenu} to="/services/design/graphic-design">
                              Graphic Design
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/design/brand-identity" ? "active" : ""} onClick={closeMenu} to="/services/design/brand-identity">
                              Brand &amp; Identity Design
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/design/logo-design" ? "active" : ""} onClick={closeMenu} to="/services/design/logo-design">
                              Logo Design
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/design/nft-design" ? "active" : ""} onClick={closeMenu} to="/services/design/nft-design">
                              NFT Design
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/design/audio-video-production" ? "active" : ""} onClick={closeMenu} to="/services/design/audio-video-production"
                            >
                              Audio/Video Production Service
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                        {/* desktop End */}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link className={currentPath === "/services/digital-marketing" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing">Digital Marketing</Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("digitalMarketing")}
                            >
                              {isMobileMenuOpen === "digitalMarketing" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "digitalMarketing" ? "open" : ""
                          }`}>

                          <ul
                            className="row sub-menu-navbar menu-item-list  pl-0 column-1"
                            id="on-demand"
                          >
                            <li>
                              <Link className={currentPath === "/services/digital-marketing/ppc" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/ppc">
                                Pay Per Click (PPC){" "}
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/digital-marketing/seo" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/seo">
                                Search Engine Optimization (SEO)
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/digital-marketing/smm" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/smm">
                                Social Media Marketing (SMM)
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/digital-marketing/email-marketing" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/email-marketing">
                                Email Marketing
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/digital-marketing/content-marketing" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/content-marketing"
                              >
                                Content Marketing
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/digital-marketing/google-tag-manager" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/google-tag-manager"
                              >
                                Google Tag Managers Agency
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list  pl-0 column-1 none-for-res"
                          id="on-demand"
                        >
                          <li>
                            <Link className={currentPath === "/services/digital-marketing/ppc" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/ppc">
                              Pay Per Click (PPC){" "}
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/digital-marketing/seo" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/seo">
                              Search Engine Optimization (SEO)
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/digital-marketing/smm" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/smm">
                              Social Media Marketing (SMM)
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/digital-marketing/email-marketing" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/email-marketing">
                              Email Marketing
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/digital-marketing/content-marketing" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/content-marketing"
                            >
                              Content Marketing
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/digital-marketing/google-tag-manager" ? "active" : ""} onClick={closeMenu} to="/services/digital-marketing/google-tag-manager"
                            >
                              Google Tag Managers Agency
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">E-Commerce Services</Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("eCommerceServices")}
                            >
                              {isMobileMenuOpen === "eCommerceServices" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "eCommerceServices" ? "open" : ""
                          }`}>

                          <ul
                            className="row sub-menu-navbar menu-item-list  pl-0 column-1"
                            id="on-demand"
                          >
                            <li>
                              <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                              eCommerce Management{" "}
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                              eCommerce Marketing{" "}
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                              Shopify Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                              eBay Service
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                              Amazon Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#"
                              >
                                Etsy Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#"
                              >
                                TikTok
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list  pl-0 column-1 none-for-res"
                          id="on-demand"
                        >
                          <li>
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                            eCommerce Management{" "}
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                            eCommerce Marketing{" "}
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                            Shopify Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                            eBay Service
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#">
                            Amazon Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#"
                            >
                              Etsy Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "#" ? "active" : ""} onClick={closeMenu} to="#"
                            >
                              TikTok
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                      </div>
                      
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        {" "}
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link className={currentPath === "/services/web-developement" ? "active" : ""} onClick={closeMenu} to="/services/web-developement">Web Developement</Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("WebDevelopement")}
                            >
                              {isMobileMenuOpen === "WebDevelopement" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "WebDevelopement" ? "open" : ""
                          }`}>
                          <ul
                            className="row sub-menu-navbar menu-item-list pl-0 column-1"
                            id="digital-marketing"
                          >
                            <li>
                              <Link

                                className={currentPath === "/services/web-developement/front-enddevelopment" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/front-enddevelopment"
                              >
                                Front-end development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/web-developement/back-end" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/back-end">
                                Back-end development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/web-developement/web-app" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/web-app"
                              >
                                Web App Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/web-developement/web-portal" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/web-portal"
                              >
                                Web Portal Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list pl-0 column-1 none-for-res"
                          id="digital-marketing"
                        >
                          <li>
                            <Link

                              className={currentPath === "/services/web-developement/front-enddevelopment" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/front-enddevelopment"
                            >
                              Front-end development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/web-developement/back-end" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/back-end">
                              Back-end development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/web-developement/web-app" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/web-app"
                            >
                              Web App Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/web-developement/web-portal" ? "active" : ""} onClick={closeMenu} to="/services/web-developement/web-portal"
                            >
                              Web Portal Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                        {/* <span className="menu_type under_line"> <a href="game-development/">Game Development</a> <button type="reset" className="button sub-menu-button collapsed" data-toggle="collapse" data-target="#digital-marketing"> <i className="menu-status"></i> </button> </span> */}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        {" "}
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link className={currentPath === "/services/cms-development" ? "active" : ""} onClick={closeMenu} to="/services/cms-development">CMS Development</Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("CMSDevelopment")}
                            >
                              {isMobileMenuOpen === "CMSDevelopment" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "CMSDevelopment" ? "open" : ""
                          }`}>
                          <ul
                            className="row sub-menu-navbar menu-item-list pl-0 column-1"
                            id="digital-marketing"
                          >
                            <li>
                              <Link

                                className={currentPath === "/services/cms-development/magento" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/magento"
                              >
                                Magento Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/cms-development/wix" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/wix">
                                Wix Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/cms-development/drupal" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/drupal"
                              >
                                Drupal Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "/services/cms-development/wordpress" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/wordpress"
                              >
                                WordPress Management
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list pl-0 column-1 none-for-res"
                          id="digital-marketing"
                        >
                          <li>
                            <Link

                              className={currentPath === "/services/cms-development/magento" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/magento"
                            >
                              Magento Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/cms-development/wix" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/wix">
                              Wix Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/cms-development/drupal" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/drupal"
                            >
                              Drupal Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "/services/cms-development/wordpress" ? "active" : ""} onClick={closeMenu} to="/services/cms-development/wordpress"
                            >
                              WordPress Management
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                        {/* <span className="menu_type under_line"> <a href="game-development/">Game Development</a> <button type="reset" className="button sub-menu-button collapsed" data-toggle="collapse" data-target="#digital-marketing"> <i className="menu-status"></i> </button> </span> */}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        {" "}
                        <span className="menu_type under_line">
                          <div className="flexer">

                            <Link className={currentPath === "/services/mobile-app-development" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development">
                              Mobile App Development
                            </Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("MobileAppDevelopment")}
                            >
                              {isMobileMenuOpen === "MobileAppDevelopment" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "MobileAppDevelopment" ? "open" : ""
                          }`}>
                          <ul
                            className="row sub-menu-navbar menu-item-list pl-0 column-1"
                            id="digital-marketing"
                          >
                            <li>
                              <Link className={currentPath === "/services/mobile-app-development/ios-mobile-app" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/ios-mobile-app">
                                ISO App Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/mobile-app-development/android-mobile-app" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/android-mobile-app">
                                Andriod App Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link className={currentPath === "/services/mobile-app-development/cross-platform" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/cross-platform">
                                Cross Platfoms
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list pl-0 column-1 none-for-res"
                          id="digital-marketing"
                        >
                          <li>
                            <Link className={currentPath === "/services/mobile-app-development/ios-mobile-app" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/ios-mobile-app">
                              ISO App Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/mobile-app-development/android-mobile-app" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/android-mobile-app">
                              Andriod App Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link className={currentPath === "/services/mobile-app-development/cross-platform" ? "active" : ""} onClick={closeMenu} to="/services/mobile-app-development/cross-platform">
                              Cross Platfoms
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                        {/* <span className="menu_type under_line"> <a href="game-development/">Game Development</a> <button type="reset" className="button sub-menu-button collapsed" data-toggle="collapse" data-target="#digital-marketing"> <i className="menu-status"></i> </button> </span> */}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 resp-submenu">
                        {" "}
                        <span className="menu_type under_line">
                          <div className="flexer">
                            <Link to='#'>Others</Link>
                            <span
                              className="none-for-web"
                              onClick={() => handleMobileMenuToggle("other")}
                            >
                              {isMobileMenuOpen === "other" ? <RxCross2 /> : <IoIosArrowDown />}
                            </span>
                          </div>
                        </span>
                        <div className={`submenu-container ${isMobileMenuOpen === "other" ? "open" : ""
                          }`}>
                          <ul
                            className="row sub-menu-navbar menu-item-list pl-0 column-1"
                            id="digital-marketing"
                          >
                            <li>
                              <Link

                                className={currentPath === "services/software-development-services" ? "active" : ""} onClick={closeMenu} to="services/software-development-services"
                              >
                                Software Development Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "services/ott-app-development-services" ? "active" : ""} onClick={closeMenu} to="services/ott-app-development-services"
                              >
                                OTT App Development Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "services/api-Integration-services" ? "active" : ""} onClick={closeMenu} to="services/api-Integration-services"
                              >
                                API Integration Services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "services/strategy-consulting-services" ? "active" : ""} onClick={closeMenu} to="services/strategy-consulting-services"
                              >
                                Strategy consulting services
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                            <li>
                              <Link

                                className={currentPath === "services/staff-augmentation" ? "active" : ""} onClick={closeMenu} to="services/staff-augmentation"
                              >
                                Staff Augmentation
                              </Link>
                              <FaArrowCircleRight />
                            </li>

                            <li>
                              <Link

                                className={currentPath === "services/game-development" ? "active" : ""} onClick={closeMenu} to="services/game-development"
                              >
                                Game Development
                              </Link>
                              <FaArrowCircleRight />
                            </li>
                          </ul>
                        </div>
                        <ul
                          className="row sub-menu-navbar menu-item-list pl-0 column-1 none-for-res"
                          id="digital-marketing"
                        >
                          <li>
                            <Link

                              className={currentPath === "services/software-development-services" ? "active" : ""} onClick={closeMenu} to="services/software-development-services"
                            >
                              Software Development Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "services/ott-app-development-services" ? "active" : ""} onClick={closeMenu} to="services/ott-app-development-services"
                            >
                              OTT App Development Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "services/api-Integration-services" ? "active" : ""} onClick={closeMenu} to="services/api-Integration-services"
                            >
                              API Integration Services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "services/strategy-consulting-services" ? "active" : ""} onClick={closeMenu} to="services/strategy-consulting-services"
                            >
                              Strategy consulting services
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link
                              className={currentPath === "services/staff-augmentation" ? "active" : ""} onClick={closeMenu} to="services/staff-augmentation"
                            >
                              Staff Augmentation
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "services/game-development" ? "active" : ""} onClick={closeMenu} to="services/game-development"
                            >
                              Game Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                          <li>
                            <Link

                              className={currentPath === "services/On-Demand-App-Development" ? "active" : ""} onClick={closeMenu} to="services/On-Demand-App-Development"
                            >
                              On Demand App Development
                            </Link>
                            <FaArrowCircleRight />
                          </li>
                        </ul>
                        {/* <span className="menu_type under_line"> <a href="game-development/">Game Development</a> <button type="reset" className="button sub-menu-button collapsed" data-toggle="collapse" data-target="#digital-marketing"> <i className="menu-status"></i> </button> </span> */}
                      </div>
                    </div>

                    {/* <div
                      className="row py-4 px-4 spacialitiesTopNav"
                      style={{ background: "#314252" }}
                    >
                      <div className="m-auto col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
                        <div className="core_namee">
                          <h4 className="mb-0">Core Services</h4>
                        </div>
                      </div>
                      <div className="my-4 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
                        <div className="core_menu">
                          <div className="core_icon">
                            <FiSmartphone />
                          </div>
                          <div className="menu_items">
                            <Link to="/services/mobile-app-development" onClick={closeMenu}>
                              Mobile Application
                              <span>IOS, Android and wearable Apps</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="my-4 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
                        <div className="core_menu">
                          <div className="core_icon">
                            <BiSolidJoystick />
                          </div>
                          <div className="menu_items">
                            <Link to="/services/game-development" onClick={closeMenu}>
                              Game Development
                              <span>2D, 3D and Isometric Games</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="my-4 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
                        <div className="core_menu">
                          <div className="core_icon">
                            <LiaLaptopCodeSolid />
                          </div>
                          <div className="menu_items">
                            <Link to="/services/software-development-services" onClick={closeMenu}>
                              Software Development
                              <span>Startups &amp; Enterprises</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            <Link className="nav-link" to="/technologies" onClick={closeMenu}>
              Technology
            </Link>
            <Link className="nav-link" to="/portfolio" onClick={closeMenu}>
              Portfolio
            </Link>
            <Nav.Link href="#">Blogs</Nav.Link>
            <Link className="nav-link" to="/about-us" onClick={closeMenu}>
              About Us
            </Link>
          </Nav>
          <form className="form-inline my-2 my-lg-0 inquire_now">
            <div>
              <Link className="cmr-outline-btn hvr-shutter-out-horizontal " onClick={closeMenu} to="/contact-us">
                Let's Talk
              </Link>
            </div>
          </form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNav;
