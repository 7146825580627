import React from 'react'
import { CMsection } from "../../DesignInnerPages/LogoDesign/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/LogoDesign/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/LogoDesign/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/LogoDesign/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/LogoDesign/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/LogoDesign/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/LogoDesign/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/LogoDesign/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/LogoDesign/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/LogoDesign/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/LogoDesign/InnerPgSec";
import Faqs from '../../DesignInnerPages/LogoDesign/Faqs';
import { ContactForm } from '../../DesignInnerPages/LogoDesign/ContactForm';

export const LogoDesign = () => {
  return (
    <>
    <ServicePageBanner/>
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection/>
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
