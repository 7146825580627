import React from 'react'
import { CMsection } from "../../MobileAppInnerPages/AndroidApps/CMsection";
import { WeOfferSection } from "../../MobileAppInnerPages/AndroidApps/WeOfferSection";
import { LetDiscussSection } from "../../MobileAppInnerPages/AndroidApps/LetDiscussSection";
import { WhatWeDoSection } from "../../MobileAppInnerPages/AndroidApps/WhatWeDoSection";
import { EngagingSteps } from "../../MobileAppInnerPages/AndroidApps/EngagingSteps";
import { DominExpertiseSec } from "../../MobileAppInnerPages/AndroidApps/DominExpertiseSec";
import { TechnologyStackSection } from "../../MobileAppInnerPages/AndroidApps/TechnologyStackSection";
import { RelatedBlogSection } from "../../MobileAppInnerPages/AndroidApps/RelatedBlogSection";
import { CTASection } from "../../MobileAppInnerPages/AndroidApps/CTASection";
import { ServicePageBanner } from "../../MobileAppInnerPages/AndroidApps/ServicePageBanner";
import { InnerPgSec } from '../../DesignInnerPages/UiUxComponent/InnerPgSec';
import Faqs from '../../MobileAppInnerPages/AndroidApps/Faqs';
import { ContactForm } from '../../MobileAppInnerPages/AndroidApps/ContactForm';

export const AndroidApps = () => {
  return (
    <>
    <ServicePageBanner />
      {/* <InnerPgSec/> */}
      <CMsection />
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
