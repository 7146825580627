import React from 'react'
import { ServicePageBanner } from '../WebDevelopementPageComponent/ServicePageBanner'
import { CMsection } from '../WebDevelopementPageComponent/CMsection'
import { WeOfferSection } from '../WebDevelopementPageComponent/WeOfferSection'
import { LetDiscussSection } from '../WebDevelopementPageComponent/LetDiscussSection'
import { WhatWeDoSection } from '../WebDevelopementPageComponent/WhatWeDoSection'
import { EngagingSteps } from '../WebDevelopementPageComponent/EngagingSteps'
import { ContactForm } from '../WebDevelopementPageComponent/ContactForm'
import { DominExpertiseSec } from '../WebDevelopementPageComponent/DominExpertiseSec'
import { TechnologyStackSection } from '../WebDevelopementPageComponent/TechnologyStackSection'
import { RelatedBlogSection } from '../WebDevelopementPageComponent/RelatedBlogSection'
import { CTASection } from '../WebDevelopementPageComponent/CTASection'
import Faqs from '../WebDevelopementPageComponent/Faqs'
import { InnerPgSec } from '../WebDevelopementPageComponent/InnerPgSec'

export const WebDevelopement = () => {
  return (
    <>
    <ServicePageBanner/>
        <InnerPgSec/>
        {/* <CMsection/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
    </>
  )
}
