import React from 'react'

export const ThirdSec = () => {
  return (
    <>
    <div className="case-study  p-100 pt-5">
          <div className="container">
            <div className="row">
              <div className="m-auto col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="row">
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <p className="title_case">Our Case Study</p>
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Teemates</h4>
                        <p>TeeMates is a social media app for golfers where they can connect, network and share their golfing stories through TeeTimes with other buddies around the world.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Ether Legends</h4>
                        <p>Ether Legends is a "play to earn "collectible NFT - Blockchain Trading Card Game where players can earn trophies and claim rewards by winning matches!</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />
                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Reck 'N</h4>
                        <p>In a world where dining out sees digital novelty, TekRevol brought the Reck’N mobile app to life—a ground breaking resolution that redefines restaurant experiences.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Revcoin</h4>
                        <p>Rev Coin is a state-of-the-art crypto stock-based solution created by TekRevol, utilizing blockchain technology to revolutionize employee benefits.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Stop Vaping Challenge App</h4>
                        <p>To minimize vaping, Stop Vaping was designed to be a digital solution for teens and young adults to cut down on nicotine intake and e-cigarettes, and reduce adverse health effects.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Onsite Social</h4>
                        <p>OnSite Social is a revolutionary mobile application developed by TekRevol, designed to bridge the gap between the physical and digital worlds. It facilitates the users with meaningful connections according to their real-time locations.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>High Def Events</h4>
                        <p>HDE App is a one-stop digital auctions platform. It is a feature-packed mobile application with dedicated panels for creating appealing videos of products to intrigue the visitors into buying the product.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>AMJ Workplace Wellness</h4>
                        <p>Workplace Wellness is a cross-platform fitness app for companies who acknowledge the direct connection between employee wellbeing, productivity and organizational performance.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Project Impact</h4>
                        <p>S.E.L.F Web App by Project Impact is a platform to increase productivity and efficiency of the students, teachers, parents.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Soundly</h4>
                        <p>Soundly has revolutionized the way people experience music. Designed to enable music lovers to stream millions of songs from new artists through the app.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Nurse Practitioner</h4>
                        <p>The Nurse Practitioners application was built for a very specific reason – to revolutionize the healthcare system and convey the positive impact of technology to the end users.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Save &amp;Win</h4>
                        <p>Save &amp;Win is a mobile application that encourages users to save money and get rewarded for it. Basically, this type of money-saving application allows users to compare the prices of their favorite products.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Al Hussaini</h4>
                        <p>Al Hussaini Trading Company is a leading marketplace in Saudi Arabia for watches and jewelry, along with an expanded portfolio that includes leather goods, and writing instruments.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Parcheesi Cash</h4>
                        <p>Parcheesi Cash is an online multiplayer Spanish board game that is a recreation of the Parcheesi – the national game of India since medieval times.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Selah</h4>
                        <p>Selah is a social app designed to connect and interact with friends and family. It 's a dedicated platform to socialize, chat, and share media with your loved ones.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Stock n Ship</h4>
                        <p>Stock n Ship is a platform designed for businesses to manage their inventory, orders, product supplies, and customer refunds in a better-redefined manner through portals.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>MoCuts</h4>
                        <p>MoCuts Pro is an online marketplace for booking registered barbers, beauticians, and other day-to-day barber services.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>NDE Offshore</h4>
                        <p>NDE Offshore is a Swedish offshore company founded in 2005, operating in Sweden, Europe, and the Middle East.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Pure Plank</h4>
                        <p>Plank is an online platform for fitness and exercise enthusiasts aiming to look after their health and exercise at home.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Smart ID</h4>
                        <p>We teamed up with the client and helped him create the SmartID, Our team worked hard to ensure that the app we create promises a quick, uncomplicated and secure experience.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Equitrip</h4>
                        <p>Equitrip is a digital platform for horse owners, horse transportation companies, and other industry stakeholders to connect and collaborate to make horse transport convenient.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>kush-Me</h4>
                        <p>Kush Me is a user-orientated app designed for people to connect with marijuana dispensaries located near them.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Passion Painting</h4>
                        <p>Passion Painting Doesn’t Just Paint Houses, It’s a One Stop Solution.Consider Passion Painting as The Premier Experts In All Kinds Of Interior &amp;Exterior Painting Jobs. Aiming To Make Your House Look As Good As New.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Into Go</h4>
                        <p>Into Go is America 's newest and coolest 'things to do 'app. Users can find information on local attractions, events, dining options, and everything else that is fun.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>qRoll</h4>
                        <p>qRoll is the mobile application for Talpool, a platform that helps people with talents and skills connect with recruiters, such as models and photographers, and find work.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Button Smasher App</h4>
                        <p>ButtonSmasher is a complete solution for gaming enthusiasts. With an authentic rating and review system, it provides a way for gamers to stay updated with the latest news in the game industry.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Beach Bandits App</h4>
                        <p>Beach bandits is a one-stop solution, beach rental app that offers a wide variety of beach equipment and accessories for a fun day at the beach.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Novelcraft</h4>
                        <p>Inspired by the New York Times Best Seller, “Beautiful Disaster,” NovelCraft allows players to step in the shoes of favorite characters, and take control of their fates.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Arrow Recovery App</h4>
                        <p>One Of A Kind, Integrated Ecommerce Platform For Buying &amp;Selling Of Catalytic Converters. Buy and sell with ease on our integrated platform, and experience hassle-free transactions like never before.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Picture Pick</h4>
                        <p>Everything begins with a crazy idea. The big idea for Picture Pick was to bring together two things that no one could refuse.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>I Remembered</h4>
                        <p>iRemembered is an app that helps users to schedule their messages in advance using one of the pre-defined templates or by customizing their unique message.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                    <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>True Vine Media</h4>
                        <p>Unity is strength, and great communities are built on foundations of strong networks and collaboration.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Select Dealer Website</h4>
                        <p>Select Dealer Service offers a complete partnership for their Dealer clients. They help their clients generate incremental income and offer exemplary services, support, and training.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Martbook</h4>
                        <p>Mart Book app is the more convenient option for farmers to explore regional markets and buy or sell animals of their choice in just three simple steps.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Talpool</h4>
                        <p>It’s said ‘two heads are better than one’, and we couldn’t agree more. It isn’t just technology that we revolutionize.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>DreamEnergy</h4>
                        <p>Certified Business Energy Professionals and experienced industry experts offer unbiased advice, before and after sale consultation.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>RE4-Sale-Signs</h4>
                        <p>Tekrevol, design and development specialists build Re-Installer to be a modern, scalable, extensible, and secure platform that extend the capabilities of real estate professionals and deliver unmatched facilities.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Waste Connections RMO</h4>
                        <p>Waste Connections had a comprehensive web app solution to manage and optimize the route for truck drivers. Hence, they opted for RMO (Route Manager Online.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Muirfield</h4>
                        <p>Today, technology is the key to turning strategies into sustainable returns. The real estate investment industry thrives on the flow, analysis.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>UNA</h4>
                        <p>The University of North Alabama, one of the top universities in the USA, is known for producing exceptional U.S. Students.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Waste Management Solution</h4>
                        <p>We design tailor-made automation software for the waste management industry that are known to Augment ROI through Automation and Technology.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Superstar</h4>
                        <p>Superstar is an end-to-end digital platform that serves as a database for Waste Connections. The app also offered other features to the internal teams of Waste Connections.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>B.O.B</h4>
                        <p>B.O.B is an ultra-modern app that lets you list your business easily and conveniently over the internet. The app acts more like a local guide to your audience.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Waste Connections</h4>
                        <p>Waste Connections decided to leverage modern technologies to solve one of the world’s biggest problems, ‘waste and garbage disposal.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Veteran App</h4>
                        <p>This app was designed to serve just one purpose – To help Veterans in any way we can! The Veteran App connects US veterans with all types of facilities that can help them re-build their lives after coming back from the frontlines.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background " style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />

                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Smackback</h4>
                        <p>The Number #1 Message Scheduling App Out There. The USP of Smackback lies in the unprecedented convenience it offers to its users.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background" style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />
                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Oz Leasing</h4>
                        <p>Our team of qualified and experienced web designers and developers have an innovative approach to implement cutting-edge technologies to create highly functional, secure, and scalable websites with high ROI.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background" style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                          <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />
                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Mobius</h4>
                        <p>Mobius Risk Group is an independent multi-asset risk advisory firm, offering appropriate market guidance to manufacturers, patrons, and capital market participants.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                  <div className="grids col-lg-6 col-md-6 col-12">
                    <div className="grid-item">
                      <div className="img_box">
                        <span className=" lazy-load-image-background" style={{color:'transparent',display:'inline-block'}}>
                          <span className="" style={{display:'inline-block'}}>
                            <img src="https://www.tealslab.com/assets/images/testing.webp" alt="" />
                        </span>
                        </span>
                      </div>
                      <div className="txt_box">
                        <h4>Moodbe</h4>
                        <p>MoodBe is a revolutionary dating app that uses the user’s mood and interests to connect them with like-minded individuals. The app is designed to match them with people who share similar passions and emotional states, helping them find their perfect match.</p>
                        <a href="#" className="border_btn" aria-label="TekRevol case studies">View Case Study</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
