import React from 'react'
import { CMsection } from "../../CmsDevelopment/WixDevelopment/CMsection";
import { WeOfferSection } from "../../CmsDevelopment/WixDevelopment/WeOfferSection";
import { LetDiscussSection } from "../../CmsDevelopment/WixDevelopment/LetDiscussSection";
import { WhatWeDoSection } from "../../CmsDevelopment/WixDevelopment/WhatWeDoSection";
import { EngagingSteps } from "../../CmsDevelopment/WixDevelopment/EngagingSteps";
import { DominExpertiseSec } from "../../CmsDevelopment/WixDevelopment/DominExpertiseSec";
import { TechnologyStackSection } from "../../CmsDevelopment/WixDevelopment/TechnologyStackSection";
import { RelatedBlogSection } from "../../CmsDevelopment/WixDevelopment/RelatedBlogSection";
import { CTASection } from "../../CmsDevelopment/WixDevelopment/CTASection";
import { ServicePageBanner } from "../../CmsDevelopment/WixDevelopment/ServicePageBanner";
import { InnerPgSec } from "../../CmsDevelopment/WixDevelopment/InnerPgSec";
import Faqs from '../../CmsDevelopment/WixDevelopment/Faqs';
import { ContactForm } from '../../CmsDevelopment/WixDevelopment/ContactForm';

export const WixDevelopment = () => {
  return (
    <>
     <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
