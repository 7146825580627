import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionFour = () => {
  return (
    <>
            <div className="service-item bottom">
                <div className="container d-flex align-items-center flex-row-reverse">
                    <div className="left" data-aos="fade-left">
                        <h3 className="title">eCommerce Applications</h3>
                        <p className="short-description">Adapt and outpace the thriving competition. We modernize and craft digital platforms for B2B and B2C companies with our full-cycle eCommerce development services. With expertise in the latest technology, we have created a reputation for building amazing stories for eCommerce companies enabling them to scale, drive growth for longtime.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="magento" href="#">Magento Development</a></li>
                                <li><a className="woocommerce" href="#">Woocommerce Development</a></li>
                                <li><a className="wordpress" href="#">Wordpress Development</a></li>
                                <li><a className="shopify" href="#">Shopify Development</a></li>
                            </ul>
                        </div>
                        <div className="cta-button"><Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">Get in Touch Today <FaArrowAltCircleRight className='banner-icon' /></Link></div>
                    </div>
                    <div className="right" data-aos="fade-right">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/images/ecommerce-application.webp')} data-src={require('../../assets/images/ecommerce-application.webp')} data-srcset={require('../../assets/images/ecommerce-application.webp')} alt="eCommerce Applications" height="420" width="610" srcset={require('../../assets/images/ecommerce-application.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>        
    </>
  )
}
