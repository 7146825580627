import React from "react";
import { CMsection } from "../../DesignInnerPages/MobileApp/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/MobileApp/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/MobileApp/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/MobileApp/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/MobileApp/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/MobileApp/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/MobileApp/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/MobileApp/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/MobileApp/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/MobileApp/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/MobileApp/InnerPgSec";
import Faqs from "../../DesignInnerPages/MobileApp/Faqs";
import { ContactForm } from "../../DesignInnerPages/MobileApp/ContactForm";
export const MobileApps = () => {
  return (
    <>
      <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  );
};
