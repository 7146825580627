
import React from 'react';
import '../assets/css/ServicePage.css';


export const WhatWeDoSection = () => {
    return (
      <>
          <div class="cmr-what-we px-30">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="sec-title">
                          <p><span>Win &amp; Wow With</span></p>
                          <h2>Our Core Services</h2>
                      </div>
                  </div>
              </div>
              <div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">01.</span><span class="step-label-custom">Research and Discovery</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">With different research methods up our sleeves, we learn about
                              the users you are inviting. We put everything together from likes to dislikes - and use this
                              insight to translate into a drooling design and flexible user experience.</p>
                      </div>
                  </div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">02.</span><span class="step-label-custom">UI Design</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">Bringing together left and right hemispheres of our brains
                              into play, we deliver visually rich design interfaces and user-centric experiences that are
                              grounded with research, loved by users and developed with best technology.</p>
                      </div>
                  </div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">03.</span><span class="step-label-custom">UX Design</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">The more seamless and glitch-free a user’s journey is, the
                              chances of meeting the targets are higher. Considering that, we pay attention to designing
                              an experience that can talk to, listen to users. We help you with UX design advantage you
                              are looking for.</p>
                      </div>
                  </div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">04.</span><span class="step-label-custom">Information Architecture</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">Usability plays a crucial role in design. With information
                              architecture skills we apply the art and logic to create best structure and organize the
                              site content and features to enable flexible site navigation.</p>
                      </div>
                  </div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">05.</span><span class="step-label-custom">MVP Development</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">We help our clients by shaping their ideas into visuals with
                              the use of story mapping and affinity diagrams. This ultimately helps turning the storyline
                              into articulate code of a solution.</p>
                      </div>
                  </div>
                  <div class="row provide-plate">
                      <div class="col-lg-4 left-tags mb-2" data-aos="fade-right"><span class="step-number-custom">06.</span><span class="step-label-custom">Usability Testing</span></div>
                      <div class="col-lg-8" data-aos="fade-left">
                          <p class="why-choose-description">With our usability skills we ensure your application or
                              website works flawlessly in the real business environment. We evaluate the user experience
                              of product at each stage, identifying what issues are overlooked by UX developers and
                              product managers.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </>
    )
  }
