import React from 'react'
import { ServicePageBanner } from '../DigitalMarketingPageComponent/ServicePageBanner'
import { CMsection } from '../DigitalMarketingPageComponent/CMsection'
import { WeOfferSection } from '../DigitalMarketingPageComponent/WeOfferSection'
import { LetDiscussSection } from '../DigitalMarketingPageComponent/LetDiscussSection'
import { WhatWeDoSection } from '../DigitalMarketingPageComponent/WhatWeDoSection'
import { EngagingSteps } from '../DigitalMarketingPageComponent/EngagingSteps'
import { ContactForm } from '../DigitalMarketingPageComponent/ContactForm'
import { DominExpertiseSec } from '../DigitalMarketingPageComponent/DominExpertiseSec'
import { TechnologyStackSection } from '../DigitalMarketingPageComponent/TechnologyStackSection'
import { RelatedBlogSection } from '../DigitalMarketingPageComponent/RelatedBlogSection'
import { CTASection } from '../DigitalMarketingPageComponent/CTASection'
import Faqs from '../DigitalMarketingPageComponent/Faqs'
import { InnerPgSec } from '../DigitalMarketingPageComponent/InnerPgSec'

export const DigitalMarketing = () => {
  return (
    <>
     <ServicePageBanner/>
        <InnerPgSec/>
        {/* <CMsection/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
    </>
  )
}
