import React from 'react'
import { AboutUsBanner } from '../AboutUs/AboutUsBanner'
import { AboutUsIntroSec } from '../AboutUs/AboutUsIntroSec'
import { AboutSpecialize } from '../AboutUs/AboutSpecialize'
import { AboutCustomize } from '../AboutUs/AboutCustomize'
import { OurMission } from '../AboutUs/OurMission'
import { CounterSec } from '../AboutUs/CounterSec'

export const About = () => {
  return (
    <>
      <AboutUsBanner/>
      <AboutUsIntroSec/>
      <AboutSpecialize/>
      <AboutCustomize/>
      <OurMission/>
      <CounterSec/>
    </>
  )
}
