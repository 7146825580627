import React from 'react';
import '../assets/css/ServicePage.css';
import { FaArrowAltCircleRight } from 'react-icons/fa';


export const RelatedBlogSection = () => {
    const director3 = [
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },
        {cate: 'Ecommerce', para: 'Algolia Search For eCommerce – Challenges, Solutions, And Implement', dte:'Oct 06, 2023' },

    ]
  return (
    <>
        <div className="blog_section">
        <div className="container">
            <div className="blog_upper mb-4">
                <div className="upper_left">
                    <h4 className="blog_section_title">Our Blogs</h4>
                </div>
                <div className="upper_right"> <a href="#" target="_blank" className="view_all_blog">View All Blogs <FaArrowAltCircleRight className='banner-icon' /></a> </div>
            </div>
            <div className="blog_list">
                <div className="row">
                    {director3.map((directors,index)=>(
                        
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                        <div className="blog_item">
                            <picture className="blog_image">
                                <img className=" ls-is-cached lazyloaded" src={require('../assets/images/best-algolia-search-for-eCommerce.webp')} data-src={require('../assets/images/best-algolia-search-for-eCommerce.webp')} alt="Algolia Search For eCommerce – Challenges, Solutions, And Implement" height="242" width="450"/> 
                            </picture>
                            <p className="blog_category">{directors.cate}</p>
                            <p className="post-title">
                                <a href="#" target="_blank">
                                    {directors.para}
                                </a>
                                </p>
                                <span className="blog_date">
                                    {directors.dte}
                                </span>
                        </div>
                    </div>
                        ))}
                </div>
            </div>
        </div>
    </div>

    </>
  )
}
