import React from 'react'
import { CMsection } from "../../CmsDevelopment/DrupalDevelopment/CMsection";
import { WeOfferSection } from "../../CmsDevelopment/DrupalDevelopment/WeOfferSection";
import { LetDiscussSection } from "../../CmsDevelopment/DrupalDevelopment/LetDiscussSection";
import { WhatWeDoSection } from "../../CmsDevelopment/DrupalDevelopment/WhatWeDoSection";
import { EngagingSteps } from "../../CmsDevelopment/DrupalDevelopment/EngagingSteps";
import { DominExpertiseSec } from "../../CmsDevelopment/DrupalDevelopment/DominExpertiseSec";
import { TechnologyStackSection } from "../../CmsDevelopment/DrupalDevelopment/TechnologyStackSection";
import { RelatedBlogSection } from "../../CmsDevelopment/DrupalDevelopment/RelatedBlogSection";
import { CTASection } from "../../CmsDevelopment/DrupalDevelopment/CTASection";
import { ServicePageBanner } from "../../CmsDevelopment/DrupalDevelopment/ServicePageBanner";
import { InnerPgSec } from "../../CmsDevelopment/DrupalDevelopment/InnerPgSec";
import Faqs from '../../CmsDevelopment/DrupalDevelopment/Faqs';
import { ContactForm } from '../../CmsDevelopment/DrupalDevelopment/ContactForm';

export const DrupalDevelopment = () => {
  return (
    <>
     <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
