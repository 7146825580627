import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionThree = () => {
  return (
    <>
       <div className="service-item right bottom">
                <div className="container d-flex align-items-center">
                    <div className="left" data-aos="fade-right">
                        <h3 className="title">Website Development</h3>
                        <p className="short-description">Websites are must have for any business. They are your global office, show rooms or shop depending on your business - seamlessly working 24/7/365. They convey your complete brand value, core offerings and an entire story. We help you build powerful websites integrated with seamless navigations and functionalities to help you reach large audiences quickly and regularly.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="php" href="#">PHP Development</a></li>
                                <li><a className="mern" href="#">Mern Stack Developers</a></li>
                                <li><a className="mean" href="#">Mean Stack Developers</a></li>
                                <li><a className="fullstack" href="#">Full Stack Developers</a></li>
                            </ul>
                        </div>
                        <div className="cta-button"><Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">Start A Project <FaArrowAltCircleRight className='banner-icon' /></Link></div>
                    </div>
                    <div className="right" data-aos="fade-left">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/images/website-development.webp')} data-src={require('../../assets/images/website-development.webp')} data-srcset={require('../../assets/images/website-development.webp')} alt="Website Development" height="432" width="583" srcset={require('../../assets/images/website-development.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
