import React from 'react'
import { CMsection } from "../../CmsDevelopment/MagentoDevelopment/CMsection";
import { WeOfferSection } from "../../CmsDevelopment/MagentoDevelopment/WeOfferSection";
import { LetDiscussSection } from "../../CmsDevelopment/MagentoDevelopment/LetDiscussSection";
import { WhatWeDoSection } from "../../CmsDevelopment/MagentoDevelopment/WhatWeDoSection";
import { EngagingSteps } from "../../CmsDevelopment/MagentoDevelopment/EngagingSteps";
import { DominExpertiseSec } from "../../CmsDevelopment/MagentoDevelopment/DominExpertiseSec";
import { TechnologyStackSection } from "../../CmsDevelopment/MagentoDevelopment/TechnologyStackSection";
import { RelatedBlogSection } from "../../CmsDevelopment/MagentoDevelopment/RelatedBlogSection";
import { CTASection } from "../../CmsDevelopment/MagentoDevelopment/CTASection";
import { ServicePageBanner } from "../../CmsDevelopment/MagentoDevelopment/ServicePageBanner";
import { InnerPgSec } from "../../CmsDevelopment/MagentoDevelopment/InnerPgSec";
import Faqs from '../../CmsDevelopment/MagentoDevelopment/Faqs';
import { ContactForm } from '../../CmsDevelopment/MagentoDevelopment/ContactForm';

export const MagentoDevelopment = () => {
  return (
    <>
     <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
