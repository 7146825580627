import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import './Homeservices.css'
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight, FaPhp } from "react-icons/fa";

export const HomeSlider = () => {
  const slider2 = [
    {
      image: require('../assets/images/terri-villafana.webp'),
      Pworking: "Ongoing 1",
      sliderHeading: "Web-app ",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
      sliderTags3: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">unknown</Link>,
    },
    {
      image: require('../assets/images/guillaume-bleau.webp'),
      Pworking: "Ongoing 2",
      sliderHeading: "Web-app at UPentreprise",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
      sliderTags3: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">unknown</Link>,

    },
    {
      image: require('../assets/images/erica-tava-johnson.webp'),
      Pworking: "Ongoing 3",
      sliderHeading: "Web-app ",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 4",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags3: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">unknown</Link>,

    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 5",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 6",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
      sliderTags3: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">unknown</Link>,

    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 7",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 8",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
    },
    {
      image: <FaPhp />,
      Pworking: "Ongoing 9",
      sliderHeading: "Web-app",
      paradetail:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. ",
      sliderTags: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">webapp</Link>,
      sliderTags2: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">mobile app</Link>,
      sliderTags3: <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">unknown</Link>,

    },
  ];
 
  return (
    <section className="teals-Hslider teals-Hslider-new-sec">
      <div className="container">
        <div className="Hslider-head Hslider-head-new">
          <div className="Hslider-title">
            <h2>Few of many words</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </div>
        {/* <div className="carousel-1"> */}
        <Swiper
        className="carousel-1"
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          600: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        >
          {slider2.map((slider, index) => (
            <SwiperSlide key={index} >
              <div className="review_lb">
                <div className="media gap-2">
                  <div className="cell-top">
                    <picture>
                      <img
                        decoding="async"
                        src={slider.image}
                        className="img-fluid lazyloaded"
                        alt="Client"
                        width="70"
                        height="70"
                      />
                    </picture>
                    
                    <p className="mt-0 client-reviwe-sec">{slider.Pworking}</p> 
                  </div>
                </div>
                <p className="sliderHeading-client">{slider.sliderHeading}</p>
                <p className="slider-para">{slider.paradetail}</p>
                <div className="homeTags">
                  <div className="cta-button">
                    {slider.sliderTags}
                  </div>
                  <div className="cta-button">
                    {slider.sliderTags2}
                  </div>
                  <div className="cta-button">
                    {slider.sliderTags3}
                  </div>
                </div>
                {/* <div className="homeTags">
                </div> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* </div> */}
        <div className="cta-button">
          <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">
            Build Your Team
            <FaArrowAltCircleRight className='banner-icon' />
          </Link>
        </div>
      </div>
    </section>
  );
};
