import React from "react";

export const OurMission = () => {
  return (
    <>
      <section class="section5 py-4 pt50 pb50">
        <div class="container-xl">
          <div class="row">
            <div class="col-md-4">
              <div class="company-vision">
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/our-vision.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/our-vision.png.webp"
                    alt="Our Vision"
                    width="47"
                    height="25"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-24 font-weight-bold mb-3 mt-3 decor-line text-left">
                  Our Vision
                </p>
                <p class="">
                  We visualize the future to be ‘mobile’. We work to assure that
                  there is an emotional connection, not only between your users
                  and your app but also between the users and your brand.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="company-vision">
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/our-mission.png.webp"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/our-mission.png.webp"
                    alt="Our Mission"
                    width="44"
                    height="44"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-24 font-weight-bold mb-3 mt-3 decor-line text-left">
                  Our Mission
                </p>
                <p class="">
                  Every project we take is as exciting as a Space Mission for
                  us. And making us yearn for more. We aim to touch as many
                  human lives as possible and help them achieve their goals.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="company-vision">
                <figure class="text-center">
                  <img
                    data-lazyloaded="1"
                    src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/Our-Core-Values.png"
                    decoding="async"
                    data-src="https://www.spaceotechnologies.com/wp-content/uploads/2021/02/Our-Core-Values.png"
                    alt="Our Core Values"
                    width="57"
                    height="47"
                    data-ll-status="loaded"
                    class="entered litespeed-loaded"
                  />
                </figure>
                <p class="fonts-24 font-weight-bold mb-3 mt-3 decor-line text-left">
                  Our Core Values
                </p>
                <p class="">
                  We have five pillars that we have built our empire on
                  Attitude, Integrity, Transparency, Excellence and
                  Professionalism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
