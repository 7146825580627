import React, { useState } from 'react';
// Uncomment the following line to include the FAQ image if needed
// import Faqimage from '../assets/images/faqimage.png';
import { FaArrowAltCircleRight } from "react-icons/fa";
const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'Why is UI/UX design important for SaaS?',
      answer:
        'Software as a Service (SaaS) applications are some of the most intricate programs ever created. Implementing UX best practices for any SaaS platform is a worthwhile endeavor that can yield significant benefits: reduce customer churn and enhance user efficiency (and therefore satisfaction) by simplifying the process. We at CMARIX are proud to say that our UI/UX team is among the best in the industry, thanks to the expertise of our designers.',
    },
    {
      question: 'Why are Indian software outsourcing companies highly preferred worldwide?',
      answer:
        'The affordability and availability of highly skilled software engineers are the key factors that make Indian companies the most preferred for outsourcing. Government regulations are also flexible, allowing businesses to outsource successfully. We at CMARIX are delighted to talk about the quality of our service, as our designers are experienced professionals with a wealth of knowledge.',
    },
    {
      question: 'Who will be the contact person during the project?',
      answer:
        'Working on your project will be a team effort. There will always be a project manager on the team to keep you updated on everything. Most companies won\'t allow you much access to the designer or developer working on your project, but at CMARIX, we set up a communication system so that you can speak with them directly and receive updates regularly.',
    },
    {
      question: 'How much does it cost to hire a UI/UX designer?',
      answer:
        'The cost of hiring a UX designer depends on your plan. For instance, if you decide to hire locally and internally, you\'ll need to match the prices in your local market. The hourly pricing will primarily depend on the location if you engage a freelancer or an outsourced firm. Compared to others in the industry, we at CMARIX offer you the best pricing.',
    },
    // Add more FAQ items as needed
  ];

  return (
      <section id="faqs">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-8">
          <div class="sec-title mb-4 mobile-custom-spacing-bottom-4"><p><span>Take A Look At</span></p><h3><span></span>Some Of FAQs</h3></div>
              {/* <h3>Frequently Asked Questions</h3> */}
              <div className="accordion">
                {faqItems.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
                      onClick={() => toggleAccordion(index)}
                    >
                      <p>{item.question}</p>
                      <span className="accordion-icon">
                        {activeIndex === index ? '-' : '+'}
                      </span>
                    </button>
                    {activeIndex === index && (
                      <div className="accordion-content">{item.answer}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
            <div className="faq-right-section">
                        <div className="right-section-title">
                            <p>Having Same Doubts?</p>
                            <p className="faq-bold-para">We can clear all your doubt quickly...</p>
                        </div><a href="#" className="cmr-outline-btn hvr-shutter-out-horizontal">Inquire Now
                            &nbsp;<FaArrowAltCircleRight className='banner-icon' /></a>
                    </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Faqs;
