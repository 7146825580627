import React from 'react';
import '../assets/css/ServicePage.css';

import {  BsArrowRightCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';

export const CTASection = () => {
  return (
    <>
        <div className="start_project px-30">
        <div className="container">
            <div className="hire_developer_orange">
                <div className="row align-items-center flex-grow-1">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-12 sb">
                        <div className="hire_left">
                            <p>Want the exact details of your vision on web?</p>
                            <p>We help you design the envision!</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 d-flex justify-content-md-end justify-content-sm-center justify-content-center sb">
                        <div className="hire_right"><Link to="#" className="cmr-outline-btn hvr-shutter-out-horizontal">Let’s Talk Ideas <BsArrowRightCircleFill className='banner-icon' />

</Link> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}
