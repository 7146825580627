import React, { useEffect, useState } from "react";
import { CountUp } from "use-count-up";

export const CounterSec = () => {
  const [startCounting, setStartCounting] = useState(false);

  // Use useEffect to start counting when the component mounts
  useEffect(() => {
    const handleScroll = () => {
      const counterSection = document.querySelector(".counter");
      const counterSectionTop = counterSection.getBoundingClientRect().top;

      // If the counter section is in the viewport, start counting
      if (counterSectionTop < window.innerHeight) {
        setStartCounting(true);
        // Remove the scroll event listener to avoid unnecessary calculations
        window.removeEventListener("scroll", handleScroll);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <section className="counter">
        <div className="container">
          <div className="row" style={{justifyContent:'center',alignItems:'baseline'}}>
            <div className="col-lg-2 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <img src={require('../assets/images/Established.webp')} alt="" />
                <span data-toggle="counter-up">
                <CountUp  isCounting={startCounting} end={372} duration={3.2} />
                </span>
                <hr className="hrborder" />
                <p>Established</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <img src={require('../assets/images/Employees.webp')} alt="" />
                <span data-toggle="counter-up"><CountUp  isCounting={startCounting} end={13} duration={3.2} />+</span>
                <hr className="hrborder" />
                <p>Employees</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <img src={require('../assets/images/Small-And-Large-Innovation.webp')} />
                <span data-toggle="counter-up"><CountUp  isCounting={startCounting} end={57} duration={3.2} />+</span>
                <hr className="hrborder" />
                <p>Small And Large Innovation</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <img src={require('../assets/images/Clients-Worldwide.webp')} />
                <span data-toggle="counter-up"><CountUp  isCounting={startCounting} end={5} duration={3.2} />+</span>
                <hr className="hrborder" />
                <p>Clients Worldwide</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <img src={require('../assets/images/Countries.webp')} />
                <span data-toggle="counter-up"><CountUp  isCounting={startCounting} end={5} duration={3.2} />+</span>
                <hr className="hrborder" />
                <p>Countries</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};