import React from 'react'
import { TechBanner } from '../TechnologiesComponents/TechBanner/TechBanner'

export const Technologies = () => {
  return (
    <>
        <TechBanner/>
    </>
  )
}
