import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
export const SecondSec = () => {
  return (
    <>
    <div className="tekrevol_featured p-100">
          <div className="h-100 container">
            <div className="h-100 row">
              <div className="tekrevol_featured_title col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="example center text-center" data-text="Featured">
                  <h2>We’re Featured In</h2>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="project-dropdown tekrevol_gray_bg  p-100">
          <div className="container">
            <div className="row">
              <div className="my-auto col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                <div className="case-drop">
                  <h2>Case Study</h2>
                  <p>Explore TekRevol 's exceptional portfolio to witness their transformative work in action, encompassing groundbreaking web and mobile applications, bespoke software development, and captivating digital experiences that push the boundaries of technology and drive business success</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="case-dropdown-btn">
                  {/* <div className="dropdown">
                    <button type="button" id="category-dropdown" aria-expanded="false" className="dropdown-toggle btn btn-primary">Select Industry</button>
                    
                  </div> */}
                  


    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>



                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
