import React from 'react'
import { ServicePageBanner } from '../MobileAppPageComponent/ServicePageBanner'
import { CMsection } from '../MobileAppPageComponent/CMsection'
import { WeOfferSection } from '../MobileAppPageComponent/WeOfferSection'
import { LetDiscussSection } from '../MobileAppPageComponent/LetDiscussSection'
import { WhatWeDoSection } from '../MobileAppPageComponent/WhatWeDoSection'
import { EngagingSteps } from '../MobileAppPageComponent/EngagingSteps'
import { ContactForm } from '../MobileAppPageComponent/ContactForm'
import { DominExpertiseSec } from '../MobileAppPageComponent/DominExpertiseSec'
import { TechnologyStackSection } from '../MobileAppPageComponent/TechnologyStackSection'
import { RelatedBlogSection } from '../MobileAppPageComponent/RelatedBlogSection'
import { CTASection } from '../MobileAppPageComponent/CTASection'
import Faqs from '../MobileAppPageComponent/Faqs'
import { InnerPgSec } from '../MobileAppPageComponent/InnerPgSec'

export const MobileApp = () => {
  return (
    <>
    <ServicePageBanner/>
        <InnerPgSec/>
        {/* <CMsection/> */}
        <WeOfferSection/>
        <LetDiscussSection/>
        <WhatWeDoSection/>
        <EngagingSteps/>
        <ContactForm/>
        <DominExpertiseSec/>
        <TechnologyStackSection/>
        <Faqs/>
        <RelatedBlogSection/>
        <CTASection/>
    </>
  )
}
