import { useEffect } from "react";
import "./Testimonial.css";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";

export const Testimonial = () => {
  const testimonial = [
    {
      image: require('../../assets/images/terri-villafana.webp'),
      name: "Terri Villafana",
      desgination: "Digital Development Manager - Nutranext, LLC",
      review:
        "We are Clorox - American global manufacturer and marketer of consumer and professional products. I hired CMARIX in 2015 to assist our in house team in our first migration to Magento 1. After that we migrated all sites to Magento 1, and we launched a B2B portal. Since then we have migrated all sites to Magento 2 and CMARIX continues to be a strong daily contributor of our projects and day to day tasks. The team has been extremely accommodating, patient and has always remained professional and reliable.",
      developmentFor: "Magento Enterprise eCommerce Website",
    },
    {
      image: require('../../assets/images/guillaume-bleau.webp'),
      name: "Guillaume Bleau",
      desgination: "Chief Executive Officer at UPentreprise",
      review:
        "I am the president of a consulting and business solutions company. CMARIX has proven to be a reliable vendor that consistently offers practical website solutions. The team communicates well across multiple time zones and is flexible to existing practices.",
      developmentFor: "Custom Web Development",
    },
    {
      image: require('../../assets/images/erica-tava-johnson.webp'),
      name: "Erica Tava Johnson",
      desgination: "Director of Operations - Artisan Venture Lab",
      review:
        "CMARIX seamlessly manages to submit all deliverables concerning feature development on time for Magento, Wordpress and Shopify Technologies. The team maximizes their use of effective communication channels, so the client does not have to worry about not getting ample progress updates within the scope of their partnership.",
      developmentFor: "Wordpress / WooCommerce Development",
    },
    {
      image: require('../../assets/images/steve-hamilton.webp'),
      name: "Steve Hamilton",
      desgination: "CEO GameWithme.com",
      review:
        "CMARIX has transformed my concept from an Adobe XD project into a functional broadcasting platform. They’ve coded the site from scratch, building out the broadcasting features and chat function. The tech stack includes PHP Framework, HTML 5, Wowza Streaming Engine, all deployed on AWS. Currently, we’re still in the early phases of the project, but they’re providing ongoing development",
      developmentFor: "Enterprise Web Platform",
    },
  ];
  useEffect(() => {
    const flkty = new Flickity('.carousel', {
      cellAlign: 'center',
      prevNextButtons:false,
      pageDots: true,
      groupCells: '20%',
      selectedAttraction: 0.01,
      friction: 0.15,
      autoPlay: true, // Enable autoPlay
      wrapAround: true, // Enable looping
      autoPlay: 4000, // Auto-play every 3 seconds
    });

    return () => {
      flkty.destroy();
    };
  }, []);
  return (
    <>
      <section className="teals-testimonials teals-testimonials-new-sec">
        <div className="container">
          <div className="testimonial-head testimonial-head-new">
            <div className="testimonial-title">
              <span className="bubble-title" data-aos="flip-left">Everything we do is</span>
              <h2>Few of many words</h2>
            </div>
            
          </div>
        
          
          <div className="carousel">
            {testimonial.map((testimonials, index) => (
              <div className="carousel-cell" key={index}>
                <div className="review_lb">
                  <div className="d-flex align-items-center media gap-2">
                    <picture>
                      <img
                        decoding="async"
                        src={testimonials.image}
                        data-src={testimonials.image}
                        className="img-fluid lazyloaded"
                        alt="Terri Villafana"
                        width="70"
                        height="70"
                      />
                    </picture>
                    <div className="media-body">
                      <p className="mt-0 client-reviwe-sec">{testimonials.name}</p>
                      <p className="desgination-client">
                        {testimonials.desgination}
                      </p>
                    </div>
                  </div>
                  <p>{testimonials.review}</p>
                  <p className="align-at-bottom">
                    <span>Development For:</span>
                    {testimonials.developmentFor}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
