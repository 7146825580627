import React from 'react'
import { CMsection } from "../../DigitalMarketingInnerPages/EmailMarketting/CMsection";
import { WeOfferSection } from "../../DigitalMarketingInnerPages/EmailMarketting/WeOfferSection";
import { LetDiscussSection } from "../../DigitalMarketingInnerPages/EmailMarketting/LetDiscussSection";
import { WhatWeDoSection } from "../../DigitalMarketingInnerPages/EmailMarketting/WhatWeDoSection";
import { EngagingSteps } from "../../DigitalMarketingInnerPages/EmailMarketting/EngagingSteps";
import { DominExpertiseSec } from "../../DigitalMarketingInnerPages/EmailMarketting/DominExpertiseSec";
import { TechnologyStackSection } from "../../DigitalMarketingInnerPages/EmailMarketting/TechnologyStackSection";
import { RelatedBlogSection } from "../../DigitalMarketingInnerPages/EmailMarketting/RelatedBlogSection";
import { CTASection } from "../../DigitalMarketingInnerPages/EmailMarketting/CTASection";
import { ServicePageBanner } from "../../DigitalMarketingInnerPages/EmailMarketting/ServicePageBanner";
import { InnerPgSec } from "../../DigitalMarketingInnerPages/EmailMarketting/InnerPgSec";
import Faqs from '../../DigitalMarketingInnerPages/EmailMarketting/Faqs';
import { ContactForm } from '../../DigitalMarketingInnerPages/EmailMarketting/ContactForm';
export const EmailMarketting = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
