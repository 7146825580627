import React from 'react';
import '../../assets/css/ServicePage.css';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';


export const LetDiscussSection = () => {
  return (
    <>
        <div class="let-discuss mb-40">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="discuss-left">
                        <div class="discuss-title">
                            <p class="discuss-title-heading">Design Is An <span>Attractive</span> Aspect. But is it everything?</p>
                            <p class="mt-3"><i>Visual elements will always be the matter of first impression, but only
                                    good looks won’t be inclusive for digital transformation. To see your idea turning
                                    real onto the web, you will need good developers and strategists.</i></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="discuss-right left-brush">
                        <p>Want More</p><Link to="contact-us/"><button class="cmr-outline-btn hvr-shutter-out-horizontal">Let’s Develop<FaArrowAltCircleRight className='banner-icon' /></button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
