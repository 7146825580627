import React from 'react'
import { CMsection } from "../../MobileAppInnerPages/CrossPlatForm/CMsection";
import { WeOfferSection } from "../../MobileAppInnerPages/CrossPlatForm/WeOfferSection";
import { LetDiscussSection } from "../../MobileAppInnerPages/CrossPlatForm/LetDiscussSection";
import { WhatWeDoSection } from "../../MobileAppInnerPages/CrossPlatForm/WhatWeDoSection";
import { EngagingSteps } from "../../MobileAppInnerPages/CrossPlatForm/EngagingSteps";
import { DominExpertiseSec } from "../../MobileAppInnerPages/CrossPlatForm/DominExpertiseSec";
import { TechnologyStackSection } from "../../MobileAppInnerPages/CrossPlatForm/TechnologyStackSection";
import { RelatedBlogSection } from "../../MobileAppInnerPages/CrossPlatForm/RelatedBlogSection";
import { CTASection } from "../../MobileAppInnerPages/CrossPlatForm/CTASection";
import { ServicePageBanner } from "../../MobileAppInnerPages/CrossPlatForm/ServicePageBanner";
import { InnerPgSec } from "../../MobileAppInnerPages/CrossPlatForm/InnerPgSec";
import Faqs from '../../MobileAppInnerPages/CrossPlatForm/Faqs';
import { ContactForm } from '../../MobileAppInnerPages/CrossPlatForm/ContactForm';

export const CrossPlatform = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
