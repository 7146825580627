import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionTwo = () => {
  return (
    <>
         
         <div className="service-item bottom">
                <div className="container d-flex align-items-center flex-row-reverse">
                    <div className="left"  data-aos="fade-left">
                        <h3 className="title">Enterprise Software Development</h3>
                        <p className="short-description">When you want to bring new records on ground, working with any off-the-shelf software will not make your game go further. With our custom enterprise software development services, we map all the challenges you have and deliver groundbreaking software. We complete your digital ecosystem with power-pack fully functional, featured custom software.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="software-development" href="#">Software Development</a></li>
                                <li><a className="on-demand" href="#">On Demand Solutions</a></li>
                                <li><a className="aspnet" href="#">ASP.Net Development</a></li>
                                <li><a className="dedicated-developers" href="#">Hire Dedicated Developers</a></li>
                            </ul>
                        </div>
                        <div className="cta-button"><Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">Build Your Team <FaArrowAltCircleRight className='banner-icon' /></Link></div>
                    </div>
                    <div className="right" data-aos="fade-right">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/images/enterprise-software-development.webp')} data-src={require('../../assets/images/enterprise-software-development.webp')} data-srcset={require('../../assets/images/enterprise-software-development.webp')} alt="Enterprise Software Development" height="382" width="653" srcset={require('../../assets/images/enterprise-software-development.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
