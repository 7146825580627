import React from "react";
import "./ContactUs.css";
import { Link } from "react-router-dom";
import { Accordion, AccordionBody } from "react-bootstrap";
import { FaUsersGear } from "react-icons/fa6";

export const RightSideBar = () => {
  return (
    <>
      <h4>What we offer</h4>
      <div className="rightAccordian">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Ghost Writing</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-1">
              <p>
                <span>Become an author to a best seller.</span>
                <br />
                We have a team of professional ghostwriters coming from diverse
                backgrounds such as business, arts, sciences, technology, media,
                marketing, social sciences, and engineering.
              </p>
              <a href="ghost-writing/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Editing</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-2">
              <p>
                <span>Professional Editing Services.</span>
                <br />
                The right editing services can increase the chances of getting
                the recognition your book deserves. We work with the goal to get
                your work printed and published. We offer comprehensive editing
                solutions and after-service support to make sure your work
                outshines and meets the approval of the literary agent.
              </p>
              <a href="editing/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Proof reading</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-3">
              <p>
                <span>
                  Professional, On-Point, 100% Error-Free Proofreading!{" "}
                </span>
                <br />
                Our proofreaders have a keen eye for details. No typo, no error,
                and no mistake can escape their perusing lens. Whether you need
                a thorough review or extensive editing, we tailor our services
                to circumscribe your manuscript's need.
              </p>
              <a href="proofreading/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Formatting</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-4">
              <p>
                <span>Formatting Services</span> <br />
                We provide you the services to get your book in proper format in
                order for it to be published. Our editors have vast experience
                of book formatting and will edit your piece as per the
                International Publishing Standard. Whether you need formatting
                for a Book / Novel or some part of it.
              </p>
              <a href="formatting/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Book covers (Front, Back & Spine)</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-5">
              <p>
                <span>Don’t judge a book by its cover? You should..!</span>{" "}
                <br />
                The book cover says a lot about the book and it is your first
                interaction point with your readers. Your book cover should be
                attractive enough to gather the reader’s attention at the very
                first glance. We have in-house experienced designer’s teams who
                will design your book’s Front, Back & Spine, Our designers are
                such experts in their respective fields that your book covers
                will be turned into your Unique Selling Point (USP).
              </p>
              <a href="book-cover/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Publishing</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-6">
              <p>
                <span>Comprehensive Publishing, Delivered! </span> <br />
                There are hundreds of thousands of writers who have put their
                heart and soul into writing but couldn’t get it published for
                some reason. At Book Writing, we have a solution for all those
                aspiring authors to get their works published professionally and
                earn the reputation they deserve for it.
              </p>
              <a href="publishing/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Digital Marketing</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-7">
              <p>
                <span>Complete Digital Marketing Solutio</span> <br />
              </p>
              <ul>
                <li>Dynamic Author’s website</li>
                <li>Audio-Book</li>
                <li>E-book publicity</li>
                <li>Video Book Trailer</li>
                <li>SEO (Search engine Optimization)</li>
                <li>Google Paid campaigns (PPC)</li>
                <li>Ads & Banners</li>
                <li>Social Media Promotions</li>
                <li>Social Media Promotions</li>
                <li>SMS Promotions</li>
                <li>Email Promotions</li>
                <li>YouTube Ads</li>
                <li>Vimeo Ads</li>
              </ul>
              <a href="book-marketing/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>Audio-Book(s)</Accordion.Header>
          <Accordion.Body>
            <div id="accordion-8">
              <p>
                <span>Why read it when you can listen it ?</span> <br />
                Recent survey shows the increase of Audio-book purchases by 27%
                every year from 2014 till this date. Like all of us, while
                travelling, tend to listen more rather than reading a book. We,
                at Authors Breezecompose and publish your book in the Audio
                format as well. To gain your book the revenue and sales that you
                are looking forward to. For this only purpose we have more than
                25 teams of artists which consist of many different Voice-Over
                Artists (VOA) who records your book in a way that it just makes
                the listeners listen more and more to it
              </p>
              <a href="audio-book/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header></Accordion.Header>
          <Accordion.Body>
            <div id="accordion-9" className="quest-content ">
              <p>
                <span>
                  to permission for motion picture adaptation, you reserve all
                  the rights for your book
                </span>
                <br />
                Authors Breezehave a team of paralegals and attorneys to help
                you file the and get you the e-copy of your certificate so that
                your work as an author doesn’t get copied or stolen and your
                work stays safe
              </p>
              <a href="audio-book/">Read more...</a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </div>
    </>
  );
};
