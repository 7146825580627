import React from 'react'
import { CMsection } from "../../Others/StaffAugmentation/CMsection";
import { WeOfferSection } from "../../Others/StaffAugmentation/WeOfferSection";
import { LetDiscussSection } from "../../Others/StaffAugmentation/LetDiscussSection";
import { WhatWeDoSection } from "../../Others/StaffAugmentation/WhatWeDoSection";
import { EngagingSteps } from "../../Others/StaffAugmentation/EngagingSteps";
import { DominExpertiseSec } from "../../Others/StaffAugmentation/DominExpertiseSec";
import { TechnologyStackSection } from "../../Others/StaffAugmentation/TechnologyStackSection";
import { RelatedBlogSection } from "../../Others/StaffAugmentation/RelatedBlogSection";
import { CTASection } from "../../Others/StaffAugmentation/CTASection";
import { ServicePageBanner } from "../../Others/StaffAugmentation/ServicePageBanner";
import { InnerPgSec } from "../../Others/StaffAugmentation/InnerPgSec";
import Faqs from '../../Others/StaffAugmentation/Faqs';
import { ContactForm } from '../../Others/StaffAugmentation/ContactForm';

export const StaffAugmentation = () => {
  return (
    <>
    <ServicePageBanner />
      {/* <InnerPgSec/> */}
      <CMsection />
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
      </>
  )
}
