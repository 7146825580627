import React from 'react';
import '../../assets/css/ServicePage.css';
import { AiFillAndroid, AiOutlineApple } from "react-icons/ai";
import { BiLogoFlutter, BiLogoReact } from "react-icons/bi";
import { TbBrandKotlin, TbBrandSwift, TbBrandSymfony, TbBrandXamarin, TbDeviceImacCode, TbDeviceIpadHorizontal } from "react-icons/tb";
import { IoLogoIonic, IoLogoPwa } from "react-icons/io5";
import { FaArrowAltCircleRight, FaDrupal, FaLaravel, FaMagento, FaNodeJs, FaPhp, FaShopify, FaVuejs, FaWordpress } from 'react-icons/fa';
import { PiAngularLogo } from "react-icons/pi";
import { SiDotnet, SiExpress, SiFrontendmentor, SiMeteor, SiWoo } from "react-icons/si";
import { BsFiletypePsd } from 'react-icons/bs';
import { DiCodeigniter, DiYii, DiZend } from 'react-icons/di';
import { DiDotnet } from 'react-icons/di';
export const TechnologyStackSection = () => {
  return (
    <>
        <div className="container mini-container px-30">
        <div className="cmr-techstack text-center mobile-custom-spacing-bottom-4">
            <div className="sec-title mb-4 mobile-custom-spacing-bottom-4">
                <p><span>All about our</span></p>
                <h3><span>Technology</span> Stack</h3>
            </div>
            <div className="row justify-content-center mt-4 mobile-custom-spacing-top ml-0 mr-0">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 custom-gutter">
                    <div className="card techstack">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>MOBILE</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6"> <a className="d-flex align-items-center" href="android-app-development.html"> <AiFillAndroid />
                                    <p>Android</p>
                                </a> <a className="d-flex align-items-center" href="#"> <AiOutlineApple />
                                    <p>iOS</p>
                                </a> <a className="d-flex align-items-center" href="#"> <BiLogoFlutter />
                                    <p>Flutter</p>
                                </a> <a className="d-flex align-items-center" href="#"> <TbBrandKotlin />
                                    <p>Kotlin</p>
                                </a> <a className="d-flex align-items-center" href="#"> <IoLogoIonic />
                                    <p>Ionic</p>
                                </a> </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6"> <a className="d-flex align-items-center" href="#"> <BiLogoReact />
                                    <p>React Native</p>
                                </a> <a className="d-flex align-items-center" href="#"> <TbBrandXamarin/>
                                    <p>Xamarin</p>
                                </a> <a className="d-flex align-items-center" href="#">
                                <IoLogoPwa />
                                    <p>PWA</p>
                                </a> <a className="d-flex align-items-center" href="#"> <TbDeviceIpadHorizontal />
                                    <p>iPad</p>
                                </a> <a className="d-flex align-items-center" href="#"> <TbBrandSwift />
                                    <p>Swift</p>
                                </a> </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 custom-gutter">
                    <div className="card techstack">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>FRONTEND</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6"> <a className="d-flex align-items-center" href="#"> <PiAngularLogo />
                                    <p>Angular</p>
                                </a> <a className="d-flex align-items-center" href="#"> <BiLogoReact />
                                    <p>ReactJs</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaVuejs />
                                    <p>VueJs</p>
                                </a> <a className="d-flex align-items-center" href="#"> <SiMeteor />
                                    <p>MeteorJs</p>
                                </a> <a className="d-flex align-items-center" href="#"> <SiExpress/>
                                    <p>ExpressJs</p>
                                </a> </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6"> <a className="d-flex align-items-center" href="#"> <TbDeviceImacCode />
                                    <p>Website</p>
                                </a> <a className="d-flex align-items-center" href="#"> <SiFrontendmentor />
                                    <p>Responsive</p>
                                </a> <a className="d-flex align-items-center" href="#"> <BsFiletypePsd />
                                    <p>PSD</p>
                                </a> </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-12 custom-gutter">
                    <div className="card techstack">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>BACKEND</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-6"> <a className="d-flex align-items-center" href="#"> <FaLaravel />
                                    <p>Laravel</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaNodeJs />
                                    <p>NodeJs</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaPhp />
                                    <p>PHP</p>
                                </a> <a className="d-flex align-items-center" href="#"> <DiYii />
                                    <p>Yii</p>
                                </a> <a className="d-flex align-items-center" href="#"> <DiCodeigniter />
                                    <p>CodeIgniter</p>
                                </a> </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-6"> <a className="d-flex align-items-center" href="#"> <SiDotnet/>
                                    <p>.Net</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaMagento />
                                    <p>Magento</p>
                                </a> <a className="d-flex align-items-center" href="#"> <DiDotnet />
                                    <p>MVC</p>
                                </a> <a className="d-flex align-items-center" href="#"> <DiZend />
                                    <p>Zend</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaShopify/>
                                    <p>Shopify</p>
                                </a> </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-6"> <a className="d-flex align-items-center" href="#"> <SiWoo/>
                                    <p>WooCommerce</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaWordpress/>
                                    <p>Wordpress</p>
                                </a> <a className="d-flex align-items-center" href="#"><TbBrandSymfony/>
                                    <p>Symfony</p>
                                </a> <a className="d-flex align-items-center" href="#"> <FaDrupal/>
                                    <p>Drupal</p>
                                </a> </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-12 custom-gutter">
                    <div className="card techstack">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>TESTING</p>
                            </div>
                            <div className="col-lg-12"> <a className="d-flex align-items-center" href="#">
                                    <i className="c-icon-qa-software-testing"></i>
                                    <p>Software Testing</p>
                                </a> <a className="d-flex align-items-center" href="#"> <i className="c-icon-support--maintenance"></i>
                                    <p>Support Testing</p>
                                </a> <a className="d-flex align-items-center" href="#"> <i className="c-icon-mobile-testing"></i>
                                    <p>Mobile Testing</p>
                                </a> </div>
                        </div>
                    </div> <a href="#" className="cmr-outline-btn hvr-shutter-out-horizontal techstack-btn">Explore
                        More <FaArrowAltCircleRight className='banner-icon' /></a>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}