import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionOne = () => {
  return (
    <>
        <div className="service-item right bottom">
                <div className="container d-flex align-items-center">
                    <div className="left" data-aos="fade-right">
                        <h3 className="title">Mobile Application Development</h3>
                        <p className="short-description">We start the transformation beyond physical screens to develop experiences that are mobile. With different native applications or cross-platform apps we craft experiences that WOW on any device. The mobile app developers at CMARIX are dedicated to revolutionize your business with creative best practices that bring seamless engagement and consistent interactions of users.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="iphone" href="#">iPhone App Development</a></li>
                                <li><a className="flutter" href="#">Flutter App Development</a></li>
                                <li><a className="android" href="#">Android App Development</a></li>
                                <li><a className="ionic" href="#">Ionic App Development</a></li>
                            </ul>
                        </div>
                        <div className="cta-button">
                            <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us">Talk To Our Experts  <FaArrowAltCircleRight className='banner-icon' /></Link> </div>
                    </div>
                    <div className="right">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" data-aos="fade-left" className=" ls-is-cached lazyloaded" src={require('../../assets/images/mobile-application-development.webp')} data-src={require('../../assets/images/mobile-application-development.webp')} data-srcset={require('../../assets/images/mobile-application-development.webp')} alt="Mobile Application Development" height="516" width="512" srcset={require('../../assets/images/mobile-application-development.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
