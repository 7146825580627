import React from 'react';
import { BsArrowRightCircleFill, BsFiletypePsd } from 'react-icons/bs';
import { TbDeviceIpadCode, TbDeviceMobileCode } from 'react-icons/tb';
import { FiSmartphone } from 'react-icons/fi';
import { AiOutlinePrototype } from 'react-icons/ai';
import { FaHtml5 } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';

export const InnerPgSec = () => {
  const director4 = [
    {
      paraone: 'Responsive Design',
      paratwo: 'With latest design standards, we make the design accessible on all platforms.',
      icon: <CgWebsite/>
    },
    {
      paraone: 'Mobile App Design',
      paratwo: 'We make your native and cross-platform apps worth drooling for.',
      icon: <TbDeviceMobileCode/>
    },
    {
      paraone: 'Design Prototyping',
      paratwo: 'With our prototyping services, we ensure your design works for people and processes.',
      icon: <img alt='color' style={{objectFit:'contain',width:'55%'}} src={require('../../assets/images/prototype.png')} />
    },
    {
      paraone: 'PSD To HTML',
      paratwo: 'Let’s turn your beautiful design layout in PSD into responsive web pages with HTML.',
      icon: <BsFiletypePsd/>
    }
  ];

  return (
    <>
      <div className="container mini-container pt-30">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="sec-title mb-4">
              <p><span>We Provide</span></p>
              <h3>Our UX/UI Design <span>Services</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div className="cmr-app-services pb-30">
        <div className="container mini-container">
          <div className="row">
            {director4.map((director, i) => (
              <div className="col-md-6 service-alt" key={i}>
                <a href="#">
                  <div className="app-plate">
                    <div>
                      <p>{director.paraone}<br />Development</p>
                      <p>{director.paratwo}</p>
                    </div>
                    <div>
                      <div className="icon_box" id='color'>{director.icon}</div>
                      <div className="link-btn">Visit Now <BsArrowRightCircleFill className='banner-icon' /></div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
