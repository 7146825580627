import React from 'react'
import { Main } from '../HomeComponent/Main'
import { HomeService } from '../HomeComponent/HomeService'
import { DevelopmentPlateforms } from '../HomeComponent/DevlopmenPlatform/DevelopmentPlateforms'
import { Testimonial } from '../HomeComponent/Testimonial/Testimonial'
import AwardWinnerSec from '../HomeComponent/AwardWinnerSec'
import { HomeSlider } from '../HomeComponent/HomeSlider'
import Counter from '../HomeComponent/Counter'
import { DominExpertiseSec } from '../HomeComponent/DominExpertiseSec'
import { RecentWork } from '../HomeComponent/RecentWork'
import { ContactForm } from '../HomeComponent/ContactForm'
import { WhyChooseUs } from '../HomeComponent/WhyChooseUs'
import { CounterSec } from '../HomeComponent/CounterSec'

export const Home = () => {
  return (
    <>
    <Main/>
    <AwardWinnerSec/>
    <HomeService/>
    {/* <Counter /> */}
    <CounterSec/>
    <HomeSlider/>
    <RecentWork/>
    <DominExpertiseSec/>
    {/* <DevelopmentPlateforms/> */}
    <Testimonial/>
    <WhyChooseUs/>
    <ContactForm/>
    </>
  )
}
