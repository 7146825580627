import React from 'react';
import '../../assets/css/ServicePage.css';

import {  BsArrowRightCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';

export const CTASection = () => {
  return (
    <>
        <div class="start_project px-30">
        <div class="container">
            <div class="hire_developer_orange">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-lg-9 col-md-9 col-sm-12 col-12 sb">
                        <div class="hire_left">
                            <p>Want the exact details of your vision on web?</p>
                            <p>We help you design the envision!</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-12 d-flex justify-content-md-end justify-content-sm-center justify-content-center sb">
                        <div class="hire_right"><Link to="/contact-us" class="cmr-outline-btn hvr-shutter-out-horizontal">Let’s Talk Ideas <BsArrowRightCircleFill className='banner-icon' /></Link></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}
