import React from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { TbDeviceIpadCode } from 'react-icons/tb'

export const InnerPgSec = () => {
            const director4 =[
                {paraone:'Responsive Design ', paratwo:'With latest design standards, we make the design accessible on all platforms.', icons:''},
                {paraone:'Mobile App Design', paratwo:'We make your native and cross-platform apps worth drooling for.'},
                {paraone:'Design Prototyping', paratwo:'With our prototyping services, we ensure your design works for people and processes.'},
                {paraone:'PSD To HTML ', paratwo:'Let’s turn your beautiful design layout in PSD into responsive web pages with HTML.'},
            ]
  return (
    <>
        <div class="container mini-container pt-30">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="sec-title mb-4">
                        <p><span>We Provide</span></p>
                        <h3>Our UX/UI Design <span>Services</span></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="cmr-app-services pb-30">
            <div class="container mini-container">
                <div class="row">
                    {director4.map((directors,i)=>(
                <div class="col-md-6 service-alt" key={i}><a href="javascript:;">
                        <div class="app-plate">
                            <div className='paraSections'>
                                <p> {directors.paraone} <br/> Development</p>
                                <p> {directors.paratwo} </p>
                            </div>
                            <div className='paraSection-icon'>
                                <div class="icon_box"> <TbDeviceIpadCode/> </div>
                                <div class="link-btn">Visit Now <BsArrowRightCircleFill className='banner-icon' /></div>
                            </div>
                        </div>
                    </a> 
                </div>
                    ))}


                </div>
            </div>
        </div>
    </>
  )
}
