import React from 'react'

export const TechLanguage = () => {
    const directors = [
        { name: '.Net', para: 'Teals uses the .NET platform to build state-of-the-art software systems. We build technologies using .NET that help you in every walk of life.' },
        { name: 'HTML5', para: 'We help businesses build robust digital solutions for internal operational and diverse customer segments, including web, apps, and SaaS.' },
        { name: 'Java', para: 'Our native app development solutions for Android have enabled many of our clients to achieve their targets and see unprecedented business growth.' },
        { name: 'Node.js', para: 'Get an interactive web app or a data-intensive program with a robust backend with Teals’s Node.js developers.' },
        { name: 'PHP', para: 'Teals is a PHP development company encompassing all the solutions you may require while developing a backend solution for your business.' },
        { name: 'Python', para: 'We are a python development company using Python for web development, CMS portals, machine learning.' },
        
        // Add more directors if needed
      ];
      const directors2 = [
        { name: 'Azure', para: 'Teals uses the .NET platform to build state-of-the-art software systems. We build technologies using .NET that help you in every walk of life.' },
        { name: 'GCP', para: 'We help businesses build robust digital solutions for internal operational and diverse customer segments, including web, apps, and SaaS.' },
        { name: 'Oracle', para: 'Our native app development solutions for Android have enabled many of our clients to achieve their targets and see unprecedented business growth.' },
        { name: 'SAP', para: 'Get an interactive web app or a data-intensive program with a robust backend with Teals’s Node.js developers.' },
     
      ];
  return (
    <>
        <section className="techlanguages p-100 pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="seriveces_listing page_listing col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="example" data-text="Languages">
                                    <h2>Languages</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        {directors.map((director, index) => (

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12" key={index}>
                                <div className="item_listing">
                                    <h4>{director.name} </h4>
                                    <p>{director.para}</p>
                                    <a href="#">
                                        <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.8148 8.20711C23.2054 7.81658 23.2054 7.18342 22.8148 6.79289L16.4509 0.428932C16.0604 0.0384078 15.4272 0.0384078 15.0367 0.428932C14.6461 0.819457 14.6461 1.45262 15.0367 1.84315L20.6935 7.5L15.0367 13.1569C14.6461 13.5474 14.6461 14.1805 15.0367 14.5711C15.4272 14.9616 16.0604 14.9616 16.4509 14.5711L22.8148 8.20711ZM0.894531 8.5H22.1077V6.5H0.894531V8.5Z" fill="#024342"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                        <div className="row">
                            <div className="seriveces_listing page_listing col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="example" data-text="Platforms">
                                    <h2>Platforms</h2>
                                </div>
                            </div>
                            {directors2.map((director2, i) =>(
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12" key={i}>
                                <div className="item_listing">
                                    <h4>{director2.name}</h4>
                                    <p>{director2.para}</p>
                                    <a href="#">
                                        <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.8148 8.20711C23.2054 7.81658 23.2054 7.18342 22.8148 6.79289L16.4509 0.428932C16.0604 0.0384078 15.4272 0.0384078 15.0367 0.428932C14.6461 0.819457 14.6461 1.45262 15.0367 1.84315L20.6935 7.5L15.0367 13.1569C14.6461 13.5474 14.6461 14.1805 15.0367 14.5711C15.4272 14.9616 16.0604 14.9616 16.4509 14.5711L22.8148 8.20711ZM0.894531 8.5H22.1077V6.5H0.894531V8.5Z" fill="#024342"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                    </div>
                </section>  
    </>
  )
}
