import React from 'react'
import { Link } from 'react-router-dom'

export const ThankYou = () => {
  return (
    <>
    <section class="login-main-wrapper">
      <div class="main-container">
          <div class="login-process">
              <div class="login-main-container">
                  <div class="thankyou-wrapper">
                      <h1>Thank You</h1>
                        <p>for contacting us, we will get in touch with you soon... </p>
                        <Link to='/'> Back to home</Link>
                        <div class="clr"></div>
                    </div>
                    <div class="clr"></div>
                </div>
            </div>
            <div class="clr"></div>
        </div>
    </section>
    </>
  )
}
