import React from 'react'
import {  ContactUsLeftSide } from '../ContactUsComponent/ContactUsLeftSide'
import { RightSideBar } from '../ContactUsComponent/RightSideBar'

export const ContactUs = () => {
  return (
    <>
        <div className='sec-padding'>

            <div className='container '>
                <div className='row'>
                    <div className='col-lg-8 inner-content'>
                        <ContactUsLeftSide/>
                    </div>
                    <div className='col-lg-4 sidebar'>
                        <RightSideBar/>
                    </div>
                </div>
            </div>
        </div>
    </>
          )
}
