import React from 'react'
import '../../assets/css/ServicePage.css';
import { Link } from 'react-router-dom'
import { FaArrowCircleRight } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';


export const ServicePageBanner = () => {
  return (
    <>
        <div class="mobile-top-hierarchy ui-ux-design-services-solution">
        <div class="inner-top-header hire-developers">
            <div class="container">
                <div class="row align-items-stretch">
                    <div class="col-md-7">
                    <section class="breadcrumbs_style">
                            <div class="h-100 container">
                            <div class="h-100 row">
                                <div class="col-12">
                                <ul class="breadcrumb contacts services">
                                    <li>
                                    <Link to="/"> Home</Link>
                                    </li>
                                    <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                                    <li>
                                    <Link to="/services/api-Integration-services"> Api Integration</Link>
                                    </li>
                                    {/* <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                                    <li>
                                    <Link to="/services/mobile-app-development/ios-mobile-app"> IOS App Development </Link>
                                    </li> */}
                                </ul>
                                {/* <ul class="breadcrumb contacts services">
                                    <li>
                                    <Link to="/"> Home</Link>
                                    </li>
                                    <li>
                                    <Link to="/services/api-Integration-services"> Api Integration</Link>
                                    </li>
                                </ul> */}
                                </div>
                            </div>
                            </div>
                        </section>
                        <div class="inner-left">
                            <div class="inner-head-title">
                                <h1><span>Api Integration </span> Service</h1>
                            </div>
                            <p class="inner-about">Design is POWER; it inspires and convinces users to stay engaged. We
                                bring power to our design with a human-centered design process by not just keeping the
                                users in mind but even by involving the users in the process. The result: A design that
                                delights users.</p>

                                <Link to="/contact-us">
                                <button class="cmr-outline-btn hvr-shutter-out-horizontal">
                                        Talk To Our Experts&nbsp;
                                        <FaArrowCircleRight className='banner-icon' />

                                    </button>

                                </Link>
                        </div>
                    </div>
                    <div class="col-md-5 text-right row align-items-end justify-content-center">
                        <div class="inner-right">
                            <picture> 
                                <img class=" ls-is-cached lazyloaded" data-src={require('../../assets/images/APIIntegration.webp')} alt="Ux Ui Design" src={require('../../assets/images/APIIntegration.webp')}/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mini-container pt-80 pb-30">
            <div class="stat-plates-box">
                <div class="sec-tag-lines mb-4"><span>What We Do</span>
                    <h2>Goals Blended With Colors – We Propel Brands To A Height With A New Look</h2>
                </div>
                <div class="stat-plates saas-apps-bg">
                    <div class="beforebg"></div>
                    <p>Grow Online</p>
                </div>
            </div>
            <div class="sec-description mt-1">
                <div>
                    <p>We no longer live in the age of same ho-hum websites that persistently use the same primary
                        color colors, exactly two fonts and retina-burning hyper-links.</p>
                    <p>In today’s era, we understand what “PRETTY” websites are and how they unlock the pretty large
                        doors of businesses and users. So, we occupied the center of old history and impeded the
                        heinous UI/UX to craft powerful applications with each pixel speaking the goal and
                        simplicity of the company, leading to world’s most innovative companies.</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
