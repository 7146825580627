import React from 'react'
import { CMsection } from "../../DesignInnerPages/BrandIdentity/CMsection";
import { WeOfferSection } from "../../DesignInnerPages/BrandIdentity/WeOfferSection";
import { LetDiscussSection } from "../../DesignInnerPages/BrandIdentity/LetDiscussSection";
import { WhatWeDoSection } from "../../DesignInnerPages/BrandIdentity/WhatWeDoSection";
import { EngagingSteps } from "../../DesignInnerPages/BrandIdentity/EngagingSteps";
import { DominExpertiseSec } from "../../DesignInnerPages/BrandIdentity/DominExpertiseSec";
import { TechnologyStackSection } from "../../DesignInnerPages/BrandIdentity/TechnologyStackSection";
import { RelatedBlogSection } from "../../DesignInnerPages/BrandIdentity/RelatedBlogSection";
import { CTASection } from "../../DesignInnerPages/BrandIdentity/CTASection";
import { ServicePageBanner } from "../../DesignInnerPages/BrandIdentity/ServicePageBanner";
import { InnerPgSec } from "../../DesignInnerPages/BrandIdentity/InnerPgSec";
import Faqs from '../../DesignInnerPages/BrandIdentity/Faqs';
import { ContactForm } from '../../DesignInnerPages/BrandIdentity/ContactForm';

export const BrandAndIdentity = () => {
  return (
    <>
    <ServicePageBanner/>
      <CMsection />
      {/* <InnerPgSec /> */}
      <WeOfferSection/>
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
