import React from 'react'
import '../../assets/css/ServicePage.css';
import { BsCart4, BsFillUmbrellaFill, BsShieldCheck, BsBookshelf, BsTruck  } from "react-icons/bs";
import { FaHeartbeat, FaHandHoldingUsd, FaHome} from "react-icons/fa";
import { RiMovie2Line } from "react-icons/ri";
import { IoFootballOutline } from "react-icons/io5";
import { GiFruitBowl, GiWheat  } from "react-icons/gi";



export const DominExpertiseSec = () => {
  return (
    <>
        <section class="industires industires-new-sec px-30">
        <div class="container">
            <div class="row mt-3 mb-4 align-items-center">
                <div class="col-md-12">
                    <div class="sec-title">
                        <p><span>Power Your Industry</span></p>
                        <h3><span>Domain Expertise</span></h3>
                    </div>
                </div>
            </div>
            <div class="industires-infocus-boxes">
                <ul>
                    <li> <a href="custom-ecommerce-platforms-development.html">
                            <div class="infocus-box-img"> <BsCart4 className = {'dominIcon'}  /> </div>
                            <p>Ecommerce</p>
                        </a> </li>
                    <li> <a href="healthcare.html">
                            <div class="infocus-box-img"> <FaHeartbeat className = {'dominIcon'}  /></div>
                            <p>Healthcare</p>
                        </a> </li>
                    <li> <a href="media-and-entertainment.html">
                            <div class="infocus-box-img"> <RiMovie2Line className = {'dominIcon'}  /> </div>
                            <p>Media</p>
                        </a> </li>
                    <li> <a href="finance-and-banking.html">
                            <div class="infocus-box-img"> <FaHandHoldingUsd className = {'dominIcon'} /></div>
                            <p>Finance</p>
                        </a> </li>
                    <li> <a href="insurance.html">
                            <div class="infocus-box-img"> <BsFillUmbrellaFill className = {'dominIcon'} /> </div>
                            <p>Insurance</p>
                        </a> </li>
                    <li> <a href="security.html">
                            <div class="infocus-box-img"> <BsShieldCheck className = {'dominIcon'} /> </div>
                            <p>Security</p>
                        </a> </li>
                    <li> <a href="elearning.html">
                            <div class="infocus-box-img"> <BsBookshelf className = {'dominIcon'} /> </div>
                            <p>Education</p>
                        </a> </li>
                    <li> <a href="#">
                            <div class="infocus-box-img"> <FaHome className = {'dominIcon'} /> </div>
                            <p>Real Estate</p>
                        </a> </li>
                    <li> <a href="sports.html">
                            <div class="infocus-box-img"> <IoFootballOutline className = {'dominIcon'} /> </div>
                            <p>Sports</p>
                        </a> </li>
                    <li> <a href="logistic-and-transportation.html">
                            <div class="infocus-box-img"> <BsTruck className = {'dominIcon'} /> </div>
                            <p>Logistic</p>
                        </a> </li>
                    <li> <a href="agritech.html">
                            <div class="infocus-box-img"> <GiWheat className = {'dominIcon'} /> </div>
                            <p>Agritech</p>
                        </a> </li>
                    <li> <a href="food-and-beverages.html">
                            <div class="infocus-box-img"> <GiFruitBowl className = {'dominIcon'} /> </div>
                            <p>Food</p>
                        </a> </li>
                </ul>
            </div>
        </div>
    </section>
    </>
  )
}
