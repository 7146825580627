import React from 'react'
import { CMsection } from "../../WebDevelopment/FrontEnd/CMsection";
import { WeOfferSection } from "../../WebDevelopment/FrontEnd/WeOfferSection";
import { LetDiscussSection } from "../../WebDevelopment/FrontEnd/LetDiscussSection";
import { WhatWeDoSection } from "../../WebDevelopment/FrontEnd/WhatWeDoSection";
import { EngagingSteps } from "../../WebDevelopment/FrontEnd/EngagingSteps";
import { DominExpertiseSec } from "../../WebDevelopment/FrontEnd/DominExpertiseSec";
import { TechnologyStackSection } from "../../WebDevelopment/FrontEnd/TechnologyStackSection";
import { RelatedBlogSection } from "../../WebDevelopment/FrontEnd/RelatedBlogSection";
import { CTASection } from "../../WebDevelopment/FrontEnd/CTASection";
import { ServicePageBanner } from "../../WebDevelopment/FrontEnd/ServicePageBanner";
import { InnerPgSec } from "../../WebDevelopment/FrontEnd/InnerPgSec";
import Faqs from '../../WebDevelopment/FrontEnd/Faqs';
import { ContactForm } from '../../WebDevelopment/FrontEnd/ContactForm';

export const FrontEndDevelopment = () => {
  return (
    <>
    <ServicePageBanner />
      <CMsection />
      {/* <InnerPgSec/> */}
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
