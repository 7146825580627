import React from 'react'
import { Hero } from '../PortfolioComponent/Hero'
import { SecondSec } from '../PortfolioComponent/SecondSec'
import { ThirdSec } from '../PortfolioComponent/ThirdSec'
export const Portfolio = () => {
  return (
    <>
    <Hero/>
    <SecondSec/>
    <ThirdSec/>
    </>
  )
}
