import React, { useEffect, useRef } from 'react';
import '../../assets/css/ServicePage.css';

export const EngagingSteps = () => {
    const stepsRef = useRef(null);
    const isMobile = window.innerWidth <= 991;  // Adjust the max-width value if needed

    useEffect(() => {
        if (isMobile) {
            const stepsContainer = stepsRef.current;
            let scrollAmount = 0;
            const autoScroll = () => {
                if (stepsContainer) {
                    stepsContainer.scrollLeft += 2;  // Adjust the scroll speed as needed
                    scrollAmount += 2;
                    if (scrollAmount >= stepsContainer.scrollWidth - stepsContainer.clientWidth) {
                        scrollAmount = 0;
                        stepsContainer.scrollLeft = 0;
                    }
                }
            };

            const intervalId = setInterval(autoScroll, 30);  // Adjust the interval as needed

            return () => clearInterval(intervalId);
        }
    }, [isMobile]);

    return (
        <>
            <div className="engage_steps">
                <div className="container mini-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec-title">
                                        <p><span>Our Process</span></p>
                                        <h2>Steps to Engage</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="container">
                        <div className="row" ref={stepsRef}>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6 step_main_parent">
                                <div className="step_block">
                                    <div className="number"><span>1</span></div>
                                    <p>Post your project requirement</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6 step_main_parent">
                                <div className="step_block">
                                    <div className="number"><span>2</span></div>
                                    <p>Discuss project details with our technical analysts</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6 step_main_parent">
                                <div className="step_block">
                                    <div className="number"><span>3</span></div>
                                    <p>Select engagement model and timeline</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6 step_main_parent">
                                <div className="step_block">
                                    <div className="number"><span>4</span></div>
                                    <p>And we start coding</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
