import React from 'react'
import { CMsection } from "../../Others/OnDemandAppDevelopment/CMsection";
import { WeOfferSection } from "../../Others/OnDemandAppDevelopment/WeOfferSection";
import { LetDiscussSection } from "../../Others/OnDemandAppDevelopment/LetDiscussSection";
import { WhatWeDoSection } from "../../Others/OnDemandAppDevelopment/WhatWeDoSection";
import { EngagingSteps } from "../../Others/OnDemandAppDevelopment/EngagingSteps";
import { DominExpertiseSec } from "../../Others/OnDemandAppDevelopment/DominExpertiseSec";
import { TechnologyStackSection } from "../../Others/OnDemandAppDevelopment/TechnologyStackSection";
import { RelatedBlogSection } from "../../Others/OnDemandAppDevelopment/RelatedBlogSection";
import { CTASection } from "../../Others/OnDemandAppDevelopment/CTASection";
import { ServicePageBanner } from "../../Others/OnDemandAppDevelopment/ServicePageBanner";
import { InnerPgSec } from "../../Others/OnDemandAppDevelopment/InnerPgSec";
import Faqs from '../../Others/OnDemandAppDevelopment/Faqs';
import { ContactForm } from '../../Others/OnDemandAppDevelopment/ContactForm';

export const OnDemandAppDevelopment = () => {
  return (
    <>
    <ServicePageBanner />
      {/* <InnerPgSec/> */}
      <CMsection />
      <WeOfferSection />
      <LetDiscussSection />
      <WhatWeDoSection />
      <EngagingSteps />
      <ContactForm/>
      <DominExpertiseSec />
      <TechnologyStackSection />
      <Faqs/>
      <RelatedBlogSection />
      <CTASection />
    </>
  )
}
